/*
    JS file structure to follow:
    - Global variables
    - Initialization
    - Events
    - Callback functions
    - General functions

    IMPORTANT! : Initializations and events are to be placed in the $(function() { ... });
    IMPORTANT! : Callback functions and general functions are to be placed outside of the $(function() { ... });

    Don't forget to comment the code in english for better understanding and maintainability of the code. And if possible use explicit variables and function names.
*/

var userWishlist = [];

// Close Achat express on swiped down
var start_point_Y;

alert = (function () {
    "use strict";

    var shade, albox, alert_tmpl;
    var regex = new RegExp("\\s", "g");

    function init() {

        shade = document.getElementById("shade");
        albox = document.getElementById("alert_box");

        alert_tmpl = albox.innerHTML.trim();

        window.removeEventListener("load", init);
    }

    function closeAlertBox() {

        modBoxClose.call(document.getElementById('alert_box'));
    }

    function alert(str) {

        $(".alert_box_content", albox).html('<p class="titre">' + str + '</p>');

        $(".close", albox).on("click", closeAlertBox);
        $(shade).one("click", closeAlertBox);

        modBoxOpen.call(document.getElementById('alert_box'));
        $("#shade_alert").addClass('actif visible');
    }

    window.addEventListener("load", init);

    return alert;
})();

$(function () {
    cart_switch_view();
    initItemSlider();
    moveProductOutOfStock();
    wording_faq_cart();
    moveTopSearchV2ToHeader();

    $(window).on("loadProduct_success, onAttrSelect_success", function () {
        if ($("body").hasClass("product_page")) {
            hidePopinColorSize();
            // remove loader
        }
    });

    $(window).scroll(function () {
        var scroll = $(window).scrollTop();
        if (scroll >= $(".banner_wrapper").height()) {
            $(".banner_wrapper").addClass("sticky");
            $("#top_searchV2").addClass("show");
            $("body").addClass("scrolled");
        } else {
            $(".banner_wrapper").removeClass("sticky");
            $("body").removeClass("scrolled");
            if(!$("#search_trigger").hasClass('search_triggered')) {
                $("#top_searchV2").removeClass("show");
            }
        }
    });

    // allows the activation of the save function of the personal message to change the quantity adding the wishlist to the basket and the basket to the wishlist
    $(window).on("generateNewBlocFilter_complete", function (data) {
        $('.loader_scroll').removeClass('loading');
    });

    // Load global .item_container slider initialization on window scroll
    window.addEventListener("scroll", function () {
        initItemSlider(false);
    });

    window.setTimeout(function () {
        sticky_filters();

        if ($('body.guide_taille').length) {
            switch_gdt_txt_swiper();
        }
    }, 500);

    // Preheader
    window.setTimeout(function () {
        if ($(".promo_swiper").length || $("#sliderBando").length && $('.bandeauMenuAffix').is(':visible')) {
            $("body").addClass("hasBando");
        } else {
            $("body").removeClass("hasBando");
        }
    }, 1000);

    $(document).on('click', '#shade', function () {
        $('#shade').removeClass('visible');
        $('.lightbox').removeClass('actif');
        $('body').removeClass('noscroll menu_open');

        if ($("#filters_menu").hasClass('open')) {
            $("#filters_menu").removeClass('open');
        }
        // Hide all modal
        $('.close_modal').click();
        $('.close_pop').click();
        $('.cart_item_actions_close.close').click();
    });

    $(document).on("click", ".toggleCarac", function () {
        $(this).toggleClass('actif');
        var nextContent = $(this).next('.content');

        nextContent.slideToggle();

        if ($(this).hasClass('actif')) {
            nextContent.addClass('actif');
        } else {
            nextContent.removeClass('actif');
        }
    });

    $(document).on('click', '.gl_thank', function () {
        $(this).hide();
        let id = $(this).data('id').replace('thankDonor', '');
        $('#thankDonor' + id).appendTo($('#donor_' + id));
    });

    $(document).on('click', '.gl_participate', function () {
        glOpenLightbox($(this));

        if ($(".list_regroup_content .lightbox .swiper-container .swiper-slide").length > 1) {
            var slider_naissance = new Swiper(".list_regroup_content .lightbox .swiper-container", {
                slidesPerView: 2.5,
                spaceBetween: 5,
            });
        }
    });

    $(document).on('click tap touch', '#main_menu_btn .menu', function () {
        if ($('#user_btn').hasClass('active')) {
            $('#user_btn').removeClass('active');
            $('#account_menu').removeClass('active');
        }
    });

    $(document).on('click tap touch', '#user_btn .user', function () {
        if ($('#left_menu').hasClass('open')) {
            $('#main_menu_btn').click();
            $('body').addClass('noscroll');
        }
    });

    $(document).on('click', '.form_submit.bloc_achat_express span, .form_submit.bloc_achat_express .color_achat_express', function () {
        $('body').addClass('noscroll');
        if (!$('#shade').hasClass('visible')) {
            $('#shade').addClass('visible isExpress');
        }
    });

    $(document).on('click', '.menu_categ', function() {
        const $parent = $(this).parent();

        if ($parent.hasClass('active')) {
            $parent.removeClass('active');
            $parent.find('.wrap_sscat_links').removeClass('show').slideUp(300);
            $parent.find('.wrap_sscat_links, #switch_lang, #menu_cate').removeClass('show').slideUp(300);
        } else {
            $('.ss_menu_categ_wrapper').removeClass('active');
            $('.ss_menu_categ_wrapper .wrap_sscat_links').removeClass('active').slideUp(300);
            $('.ss_menu_categ_wrapper .wrap_sscat_links, .menu_item #switch_lang, .menu_item #menu_cate').removeClass('active').slideUp(300);
            $parent.find('.wrap_sscat_links').slideDown(300);
            $parent.find('.wrap_sscat_links, #switch_lang, #menu_cate').slideDown(300);
            $parent.addClass('active');

            // Activate only the Swiper for the clicked category
            setTimeout(() => {
                const $pushMenuSlider = $parent.find(".pushMenuSlider");

                if ($pushMenuSlider.length && !$pushMenuSlider[0]?.swiper) {
                    const hasSlides = $pushMenuSlider.find(".swiper-wrapper .swiper-slide").length > 1;

                    if (hasSlides) {
                        new Swiper($pushMenuSlider[0], {
                            slidesPerView: 1,
                            pagination: $parent.find(".swiper-pagination")[0],
                            paginationClickable: true,
                            allowTouchMove: true,
                        });
                    }
                }
            }, 200);
        }
    });

    $(document).on('click', '.menu_item', function() {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active');
            $(this).find('.wrap_sscat_links,.ss_menu_categ_wrapper,#menu_giftlist, #switch_lang, #menu_cate').slideUp(300);
        } else {
            $('.ss_menu_categ_wrapper').removeClass('active');
            $('.ss_menu_categ_wrapper .wrap_sscat_links').removeClass('active').slideUp(300);
            $('.ss_menu_categ_wrapper .wrap_sscat_links, .menu_item #switch_lang, .menu_item #menu_cate').removeClass('active').slideUp(300);
            $(this).find('#menu_giftlist, #switch_lang, #menu_cate').slideDown(300);
            $('.menu_item').removeClass('active');
            $(this).addClass('active');
        }
    });

    $(document).on('click', '#lightbox_stock_alert .btn_connexion', function () {
        window.setTimeout(function () {
            if ($('#mail_alerte_stock').hasClass('inputErr')) {
                $('#lightbox_stock_alert .wrapper_erreur').addClass('has_error');
            } else {
                $('#lightbox_stock_alert .wrapper_erreur').removeClass('has_error');
            }
        }, 500);
    });

    $(document).on('click', '.toggle_modal_actions', function () {
        $(this).parents('.product_line_basket').find('.cart_item_actions').slideDown('slows');

        if (!$(this).parents('.product_line_basket').find('.cart_item_actions .titre_choices_list').length) {
            var modal_cart_item_actions = $(this).parents('.product_line_basket').find('.cart_item_actions');
            var modal_cart_size_title = $("<div class='titre_choices_list'>" + Translator.translate('choose_size') + "</div>");
            var block_sizeguide_cta = $("<div class='block_sizeguide'><a href='javascript:void(0);' onclick='show_sizeguide_box()' class='sizeguide'>" + Translator.translate('title_sizeguide') + "</a></div>");

            modal_cart_size_title.prependTo(modal_cart_item_actions);
            block_sizeguide_cta.appendTo(modal_cart_item_actions);
        }

        if (!$('#shade').hasClass('visible')) {
            $('#shade').addClass('visible');
        }
    });

    $(document).on('click', '.cart_item_actions_form .productSizeFieldset input', function () {
        setTimeout(function () {
            $('#shade').removeClass('visible');
        }, 3000);
    });

    $(document).on('click', '#main_menu_btn', function () {

        if ($('#main_menu_btn').hasClass('menu_open')) {
            if(!$('.supercateg_item.current').length) {
                $('.supercateg_item').first().addClass('current');
            }

            setTimeout(function () {
                var targetId = $('.supercateg_item.current').attr('id');
                var targetCateg = $('.categories').find(`ul[data-id='${targetId}']`);
                var menu_active_categ = $('.supercateg_item.current');

                $('.categories').addClass('show')
                $(targetCateg).slideDown(1000);
                $(menu_active_categ).addClass('current');

                /* Activate the push swiper for the first super category when opening the menu */
                const $firstCateg = $("#main_menu .categories .categ").first();
                const $pushMenuSlider = $firstCateg.find('> .wrap_push_menu .pushMenuSlider');

                // Ensure the element exists and Swiper is not already initialized
                if ($pushMenuSlider.length && !$pushMenuSlider[0].swiper) {
                    const hasSlides = $pushMenuSlider.find(".swiper-wrapper .swiper-slide").length > 1;

                    if (hasSlides) {
                        new Swiper($pushMenuSlider[0], {
                            slidesPerView: 1,
                            pagination: $firstCateg.find("> .wrap_push_menu .swiper-pagination")[0],
                            paginationClickable: true,
                        });
                    }
                }
            }, 500);
        } else {
            $('#shade').removeClass('visible');

            $('.categories .categ').slideUp();
            setTimeout(function () {
                $('.categories').removeClass('show');
                $('.supercateg_item').removeClass('current');
            }, 500);
        }

        $('body').toggleClass('menu_open');
    });

    $(document).on('click', '#search_trigger', function () {
        $('#top_searchV2').addClass('show');
        $("#search_homeV2").focus();
        $("#search_trigger").addClass('search_triggered');
    });

    $('#search_homeV2').on('focus', function () {
        $('.banner_nav .nav_right').addClass('focus_search');
    }).on('blur', function () {
        $('.banner_nav .nav_right').removeClass('focus_search');
    });

    // Remove class actif sur les categ quand on close
    $('.header_section .vector_sprite.menu').click(function () {
        $('.ss_menu_categ_wrapper').removeClass('active');
        $('.ss_menu_categ_wrapper .wrap_sscat_links').removeClass('active').slideUp(300);
    });

    $('.rollover.achat_express .close_modal').on('click', function () {
        $('body').removeClass('noscroll');
        $('#shade').removeClass('actif visible isExpress');

        // Reset alert Box
        var alertBox = $(this).parent().find('.bloc_add_alert');

        alertBox.find('.alert_stock').show();
        alertBox.find('.bloc_add_alert_form').show();
        alertBox.find('.bloc_add_alert_confirmation').hide();
    });

    $('.cart_item_actions_bloc .form_itm.size').on('click', function () {
        hideModalAction();
    });

    // Size guide
    $('.switch_gdt_tables tr td').each(function () {
        $(this).on('click', function () {
            var columnIndex = $(this).index();
            var active_tr = $(this).closest('tr');

            $('.switch_gdt_tables td').removeClass('actif prev_actif_td');
            $('.switch_gdt_tables tr').removeClass('active_tr inactive');
            $(this).addClass('actif');
            $(this).prevAll('td').addClass('prev_actif_td');
            active_tr.addClass('active_tr');
            active_tr.nextAll('tr').addClass('inactive');

            $('.switch_gdt_tables tr').each(function(){
                $(this).find('td:eq('+ columnIndex +')').addClass('prev_actif_td');
            });
        });
    });

    $('.rollover.achat_express, #lightboxAchatExpressV1, #alertboxlogin2, .cart_item_actions, #sizeguideBox .close_pop, .lightbox .close_pop, .modbox.open, .alertStock, #shade, #ligthbox_send_store_information').on('touchstart', function (e) {
        start_point_Y = e.originalEvent.touches[0].pageY;
    });

    $('.rollover.achat_express, #lightboxAchatExpressV1, #alertboxlogin2, .cart_item_actions, #sizeguideBox .close_pop, .lightbox .close_pop, .modbox.open, .alertStock, #shade, #ligthbox_send_store_information').on('touchend', function (e) {
        var endY = e.originalEvent.changedTouches[0].pageY;
        var deltaY = endY - start_point_Y;
        var swipeThreshold = 15;

        if (deltaY > swipeThreshold) {
            $('.close_modal').click();
            $('.close_pop').click();
            $('.cart_item_actions_close.close').click();
            modBoxClose.call(document.getElementById("item_added"));
        }
    });

    /* TG ADV */
    $('.tg-module .cms-page-module-title').click(function () {
        var module_adv_tg_id = $(this).closest(".tg-module").attr('id');
        var adv_tg_id = $(this).attr('id');
        var tg_adv_title_id = adv_tg_id.replace('-title', '');
        $("#" + module_adv_tg_id + " .cms-page-module-title, #" + module_adv_tg_id + " .cms-page-module-tg").removeClass("selected");
        $("#" + adv_tg_id).addClass("selected");
        $("#" + tg_adv_title_id).addClass("selected");
    });

    $(".cms-page-module-tg.swiper_tdg_adv").each(function () {
        $(this).find('.bloc_achat_express').click(function () {
            $('#shade').addClass('visible isExpress');
        });
    });

    $('.tag_button').on('click touch', function () {
        $('.lightbox_achat_express').addClass('for_tag_button');

        setTimeout(function () {
            if ($("#lightboxAchatExpressV1Pictures .product_swiper_new .swiper-slide").length > 2) {
                var product_swiper_new = new Swiper("#lightboxAchatExpressV1Pictures .product_swiper_new", {
                    slidesPerView: 2.2,
                    spaceBetween: 6,
                    slidesOffsetAfter: 30,
                });
            }
        }, 1000);
    });

    $(document).on("click touch", ".mot_personnalise_form .w-checkbox-input p", function () {
        $("#mot_personnalise").slideToggle();
    });

    // For Faq mobile
    var newDiv = $('<div>').addClass('theme_globalmob');
    newDiv.insertAfter('#theme_global');

    $('.faq_element').children('.faq_title').each(function () {
        ;
        $(this).appendTo($('.theme_globalmob'));
    });

    $(".theme_globalmob .faq_title").click(function (e) {
        e.preventDefault();
        // Check if this element has the "active" class
        if (!$(this).hasClass("active")) {
            // If it doesn't have the "active" class, remove it from all other elements
            $(".theme_globalmob .faq_title").removeClass("active");
        }
        // Toggle the "active" class on this element
        $(this).toggleClass("active");
    });

    /* Toggle product details in cart right column */
    $(".total_produit").on('click', function () {
        $(this).toggleClass("open");
        $(".total_produit_dropdown").slideToggle();
    });

    $('.add_new_address').click(function (event) {
        event.preventDefault();
    });

    $('.modlinks_wrapper .modify').click(function (event) {
        event.preventDefault();
    });

    $("#faq_search").click(function () {
        // Remove the "active" class from all .faq_title elements
        $(".theme_globalmob .faq_title").removeClass("active");
    });

    $('#cardCode').on('input', function () {
        var value = $(this).val();

        if (value.length >= 1) {
            // Hide the label next to cardCode
            $(this).next('.w-input-label').hide();
        } else {
            // Show the label next to cardCode
            $(this).next('.w-input-label').show();
        }
    });

    $('.footer_content .col_title').on('click touch', function () {
        var colTitle = $('.footer_content .col_title');
        var colContent = $('.footer_content .col_content');
        var mainTitle = $(this);
        var mainContent = $(this).next('.col_content');

        if (mainContent.hasClass('displayed')) {
            mainContent.slideUp(300, function () {
                mainContent.removeClass('displayed');
            });

            mainTitle.removeClass('actif');

        } else {
            colContent.slideUp(300, function () {
                colContent.removeClass('displayed');
            });
            mainContent.slideDown(300, function () {
                mainContent.addClass('displayed')
            });

            colTitle.removeClass('actif');
            mainTitle.addClass('actif');
        }
    })

    /* TG homepage */
    $('.tg-module .home-module-title').click(function () {
        var module_tg_id = $(this).closest(".tg-module").attr('id');
        var tg_id = $(this).attr('id');
        var tg_title_id = tg_id.replace('-title', '');
        $("#" + module_tg_id + " .home-module-title, #" + module_tg_id + " .home-module-tg").removeClass("selected");
        $("#" + tg_id).addClass("selected");
        $("#" + tg_title_id).addClass("selected");
    });

    $(".home-module-tg.swiper_tdg_home").each(function () {
        $(this).find('.bloc_achat_express').click(function () {
            $('#shade').addClass('visible isExpress');
        });
    });

    $(".home-module-tg.swiper_tdg_home").each(function () {
        $(this).find('.bloc_achat_express').click(function () {
            $('#shade').addClass('visible isExpress');
        });
    });

    if ($(".cms_subcategory_menu_swiper .swiper-slide").length > 3) {
        var cms_subcategory_menu_swiper = new Swiper(".cms_subcategory_menu_swiper", {
            slidesPerView: 'auto',
        });
    }

    if ($("body").hasClass("product_page")) {
        if ($("#products_look").length) {
            $("#addToWishlistButton").attr('onclick', 'addLookToWishlist.call(this)');
        }
    }

    if ($(".cms-page-columns-container .swiper-slide").length > 1) {
        var cmsPageHead = new Swiper(".slider-cover-module .cms-page-columns-container", {
            slidesPerView: 1,
            pagination: ".slider-cover-module .swiper-pagination",
            paginationClickable: true,
        });
    }

    if ($('.list_menu').length) {

        $('body').on('click', '.supercateg_item', function () {
            var targetId = $(this).attr('id');
            var targetCateg = $('.categories').find(`ul[data-id='${targetId}']`);
            var menu_active_categ = $(this);

            $('.supercateg_item').not(menu_active_categ).removeClass('current');
            $('.categories').addClass('show')

            if ($(this).hasClass('current')) {
                $(targetCateg).slideUp();
                menu_active_categ.removeClass('current');
            } else {
                $('.categ').not(targetCateg).slideUp();
                $(targetCateg).slideDown();
                $(this).addClass('current');
            }

            /* Ensure only the swiper of the clicked super-category is activated */
            var selfId = $(this).prop('id');

            setTimeout(function() {
                $('#main_menu .categories .categ').each(function() {
                    if ($(this).data('id') === selfId) {
                        var $pushMenuSlider = $(this).find('> .wrap_push_menu .pushMenuSlider');
            
                        if ($pushMenuSlider.length && !$pushMenuSlider[0].swiper) {
                            var hasSlides = $pushMenuSlider.find('.swiper-wrapper .swiper-slide').length > 1;
            
                            if (hasSlides) {
                                new Swiper($pushMenuSlider[0], {
                                    slidesPerView: 1,
                                    pagination: $(this).find('> .wrap_push_menu .swiper-pagination')[0],
                                    paginationClickable: true,
                                });
                            }
                        }
                    }
                });
            }, 200);

        });
    }

    /* Module Home */
    if ($(".home-module-body.slider-module").length) {
        var this_slider_module_container = $(".home-module-body.slider-module .swiper-container");

        if ($(".home-module-body.slider-module .swiper-slide").length > 1) {
            var home_slider_module = new Swiper(this_slider_module_container, {
                slidesPerView: 1,
                loop: true,
                autoplay: 3500, // Utilisation de l'ancienne syntaxe pour l'autoplay
                speed: 1000,
                pagination: ".slider-module .swiper-pagination",
                paginationClickable: true,
                autoplayDisableOnInteraction: false, // Option pour continuer l'autoplay même après une interaction
            });

            // Ajouter un gestionnaire d'événement pour empêcher l'arrêt du swiper lors d'un clic sur l'iframe
            this_slider_module_container.find('iframe').on('click', function(e) {
                e.stopPropagation();
                home_slider_module.startAutoplay(); // Redémarrer l'autoplay avec la méthode spécifique à Swiper v4
            });
        }
    }


    if ($(".home-module-body.cover-module .swiper-container .swiper-slide").length > 1) {
        var home_slider_module = new Swiper(".cover-module .swiper-container", {
            slidesPerView: 1,
            speed: 5000,
            loop: true,
            autoplay: 3000,
            speed: 2200,
            pagination: ".cover-module .swiper-pagination",
            paginationClickable: true,
        });
    }

    if ($(".home-module-body.image-image-image .swiper-container .swiper-slide").length > 1) {
        var home_image_image_image_module = new Swiper(".home-module-body.image-image-image .swiper-container", {
            slidesPerView: 1.417,
            spaceBetween: 6,
        });
    }

    if ($(".cms-page-module-body.three-images .swiper-container .swiper-slide").length > 1) {
        var cms_three_images = new Swiper(".cms-page-module-body.three-images .swiper-container", {
            slidesPerView: 1.4,
            spaceBetween: 5,
        });
    }

    if ($('#hashtag_module_frame').length) {
        var trigger_filtre_rayon = new Swiper('#hashtag_module_frame', {
            slidesPerView: 2.8,
            spaceBetween: 5,
        });
    }

    // preheader slider
    if ($("#sliderBando").length) {

        if ($("#sliderBando .swiper-wrapper .swiper-slide").length > 1) {

            setTimeout(function () {

                new Swiper("#sliderBando", {
                    loop: true,
                    autoplay: 3000,
                    speed: 2200,
                    effect: "slide",
                    autoplayDisableOnInteraction: false,
                });
            }, 800);
        } else {
            new Swiper("#sliderBando", {
                slidesPerView: 1
            });
        }
    }

    /* Fiche produit */

    if ($('#gondole_interest').length) {
        new Swiper('#gondole_interest', {
            slidesPerView: 2.8,
            spaceBetween: 10,
        });
    }

    if ($('#keep_alive_coupon').length) {
        $('#keep_alive_coupon .w-input-label').on('click', function () {
            $(this).parents('#keep_alive_coupon').find('#formPromo').toggleClass('actif');
            $(this).parents('#keep_alive_coupon').find('#input_codePromo').slideToggle();
        });
    }

    if ($('#virtualStore').length) {
        var virtualStore_store_loc = new Swiper("#virtualStore", {
            slidesPerView: 1,
            pagination: '.swiper-pagination',
        });
    }

    if ($(".img_store.swiper-container .swiper-slide").length > 1) {
        var store_img_swiper = new Swiper(".img_store.swiper-container", {
            slidesPerView: 1,
        });
    }

    if ($(".home-module-body.slider-module-text .swiper-slide").length > 1) {
        var slider_module_text = new Swiper(".home-module-body.slider-module-text .swiper", {
            slidesPerView: 'auto',
        });
    }

    if ($(".basket_gondole .swiper-slide.item_container").length > 1) {
        $(".basket_gondole .swiper-slide.item_container").wrapAll("<div class='item_main_wrapper'><div class='item_wrapper'></div></div>");
    }

    /* Slider account menu */
    if ($("#menu_account_slider_custom").length) {
        setTimeout(function () {
            var currentMenuAccount;

            $("#menu_account_slider_custom .swiper-slide").each(function (i, obj) {
                if ($(obj).hasClass("actif")) {
                    currentMenuAccount = i;
                }
            });

            if ($("#menu_account_slider_custom .swiper-slide").length > 1) {
                const menu_account_slider_custom = new Swiper($("#menu_account_slider_custom"), {
                    roundLengths: true,
                    slidesPerView: "auto",
                    initialSlide: currentMenuAccount,
                });
            }

            $("#menu_account_slider_custom").addClass("appear");
        }, 500);
    }

    if ($('body.newsletter').length) {
        var text_newsletter = '<p class="text_newsletter">' + Translator.translate('text_newsletter') + '</p>';
        var emailLabel = '<p class="w-input-label mail">' + Translator.translate('contact_field_mail') + '</p>';

        $(text_newsletter).insertAfter('#subscriptionForm #newsletterMail');
        $(emailLabel).insertBefore('#subscriptionForm #newsletterMail');
    }

    if ($('body').hasClass('category')) {
        var total_element = $("#wrapper_visus_produit .item_container").length;
        $(".seo_nb_product").html(total_element + ' ' + Translator.translate('label_produit'));
    }

    /* Switch cart disconnection position */
    if ($('.deconnection_tunnel').length) {
        $('.deconnection_tunnel').insertAfter($('#cart_total'));
    }

    /* Hide cart summary for new customer step 2 */
    if ($('.step_2_0').length) {
        $('#cart_total').hide();
    }

    /* Switch toggle details line position */
    if ($('.total_produit.toggle').length) {
        switchToggleCartpos();
    }

    if ($('#blocs_address').length || $('.wrapper_payment').length) {
        switchAddrRecapCartpos();
    }

    if ($(".bloc_sub_categ").length) {
        var currentCateg;

        $(".bloc_sub_categ .swiper-slide").each(function (i, obj) {
            if ($(obj).hasClass("current")) {
                currentCateg = i;
            }
        });

        if ($(".bloc_sub_categ .swiper-slide").length > 1) {
            const bloc_sub_categ = new Swiper($(".bloc_sub_categ"), {
                roundLengths: true,
                setWrapperSize: true,
                slidesPerView: "auto",
                centeredSlides: false,
                initialSlide: currentCateg,
                spaceBetween: 30,
            });
        }

        setTimeout(function () {
            $(".bloc_sub_categ").addClass("appear");
        }, 500);
    }

    /* Category SEO txt read more */
    if ($('.txt.trimed').length) {

        var trimedTxt = $('.txt.trimed');
        var fullTxt = $('.txt.full');
        var read_more = $('.read_more');
        var read_less = $('.read_less');

        read_more.on('click', function () {
            trimedTxt.addClass('cache');
            fullTxt.removeClass('cache');
        });

        read_less.on('click', function () {
            trimedTxt.removeClass('cache');
            fullTxt.addClass('cache');
        });
    }

    if ($('#scroll_items .list_item .item_container').length) {
        $('#scroll_items .list_item .item_container').each(function () {
            var eclat_rect_img = $(this).find('.item_btm img.eclat_rect');
            var eclat_rect_photo_product = $(this).find('.photo_product');

            if (eclat_rect_img.length) {
                eclat_rect_img.appendTo(eclat_rect_photo_product);
            }

            $(this).find('.close_modal').append('<span class="txt">' + Translator.translate('close') + '</span>');
        });
    }

    /* Slider cover page location */
    if ($(".slider_location_container").length) {
        var nos_location_container_tdg = new Swiper(".slider_location_container", {
            slidesPerView: 1,
        });
    }

    if ($(".bloc_4_wrapper .bloc").length > 1) {
        var location_swiper = new Swiper(".etapier_4_blocs", {
            slidesPerView: 1.5,
            spaceBetween: 13,
            pagination: ".swiper-pagination",
        });
    }

    /* Sticky page location */
    if ($(".sticky_location_bar").length) {
        var sticky_location_bar = $(".sticky_location_bar");
        var slider_location = $('.slider_location');
        var sticky_location_barH = sticky_location_bar.height();
        var slider_locationH = slider_location.height();

        $(window).scroll(function () {
            if ($(this).scrollTop() > slider_locationH) {
                sticky_location_bar.fadeIn();
                $('body').css({'margin-bottom': sticky_location_barH});
            } else {
                sticky_location_bar.fadeOut();
                $('body').css('margin-bottom', '0');
            }
        });
    }

    /* TG page location */
    if ($(".tdg_nos_offre_slider_container").length) {
        var tdg_nos_offre_slider_container = new Swiper(".tdg_nos_offre_slider_container", {
            loop: true,
            slidesPerView: 1.5,
            spaceBetween: 5,
        });
    }

    /* FAQ page location */
    if ($(".bloc_faq_location").length) {
        $(".bloc_faq_location .mes_questions ").each(function () {
            $(this).on('click', function () {
                $(".mes_reponses").slideUp();

                if ($(this).hasClass('active')) {
                    $(this).find('.mes_reponses').slideUp();
                    $(this).removeClass('active');
                } else {
                    $(this).addClass('active').find('.mes_reponses').slideDown();
                    $('.mes_questions').not(this).removeClass('active');
                }
            });
        });
    }

    if ($(".brand_container_swiper .swiper-slide").length > 2) {
        brand_container_swiper = new Swiper(".brand_container_swiper", {
            slidesPerView: 2.6,
            spaceBetween: 5,
        });
    }

    /* Carte cadeau */
    if ($("body.giftcard_index").length) {
        var text_choisir_montant = '<p class="text_choisir_montant">' + Translator.translate('choose_balance_gift_card') + '</p>';
        var text_votre_message = '<p class="text_votre_message">' + Translator.translate('your_gift_card_message') + '</p>';
        var bloc_text_info_cart = '<div class="bloc_text_info_cart"><p>' + Translator.translate('info_comment_offrir_txt') + '</p><ul><li>' + Translator.translate('info_comment_offrir_txt_1') + '</li><li>' + Translator.translate('info_comment_offrir_txt_2') + '</li><li>' + Translator.translate('info_comment_offrir_txt_3') + '</li></ul></div>';

        $('#giftCardDeliveryMethod #chooseBalance').prepend(text_choisir_montant);
        $('#giftCardSendByEmail').prepend(text_votre_message);
        $(bloc_text_info_cart).insertBefore('#giftCardSendByEmail .btn_container');

        $(".pageGiftCard section").wrapAll("<div class='new_section'></div>");

        window.setTimeout(function () {
            $("<div class='orderGiftCard_wrapper'></div>").insertAfter("#orderGiftCard");
            $("#giftCardBlance").insertAfter("#showBalance");
            $("#giftCardDeliveryMethod, #giftCardSendByEmail, #giftCardAddToCart").appendTo(".orderGiftCard_wrapper");
            $(".pageGiftCard").css("opacity", "1");
        }, 500);

        $('#orderGiftCard').on('click', function () {
            $('#orderGiftCard').toggleClass('open');
            $('.orderGiftCard_wrapper').slideToggle();
        });

        $('#showBalance').on('click', function () {
            $('#showBalance').toggleClass('open');
        });
    }

    if ($('#bank_div .payment_mode_btn').length) {
        if (!$('.wrapper_bloc_banque.gift_card').hasClass('actif')) {
            $('div[data-payment-mode="payment_gift_card"] span').on('click touch', function () {
                setTimeout(function () {
                    $('.gift_card .use_card').click();
                }, 1000);
            });
        }
    }

    if ($("body.product_page").length) {
        $('#ariane_home').appendTo('#contents_wrapper').addClass('bottom');

        if ($("#carousel_wrapper .swiper-container").length) {
            $("#carousel_wrapper").attr("id", "carousel_wrapper_new");
        }

        if ($("#gondole_derniers_articles_vus").length) {
            $("#gondole_derniers_articles_vus").attr("id", "gondole_derniers_articles_vus_new").removeClass('product_associations_wrapper').addClass('product_associations_wrapper_new');
        }

        if ($(".product_fields.size_filter .achat_express .choices_list .form_itm").length === 1) {
            $("#button_add").addClass("active");
        }
    }

    /* Liste de naissance */
    if ($(".naissance_4_blocs .bloc").length > 1) {
        var naissanceBlocs = new Swiper(".naissance_4_blocs.swiper-container", {
            slidesPerView: 1.5,
            spaceBetween: 13,
            pagination: ".swiper-pagination",
        });
    }

    if ($(".liste_naissance_slider_container .swiper-wrapper .swiper-slide").length > 1) {
        var liste_naissance_slider_container = new Swiper(".liste_naissance_slider_container ", {
            loop: false,
            slidesPerView: 1.25,
            spaceBetween: 5,
            pagination: {
                el: ".liste_naissance .liste_naissance_slider_wrapper  .swiper-pagination",
                type: "progressbar",
            },
        });
    }

    if ($('#theme_global').length) {
        checkWordAfterHash();

        $('.reinsurance .paiement').on("click touch", function () {
            $('.theme_globalmob .faq_title[data-id="4"]').click();
        });

        $('.reinsurance .livraisons_retours').on("click touch", function () {
            $('.theme_globalmob .faq_title[data-id="5"]').click();
        });

        $('.reinsurance .contact').on("click touch", function () {
            $('.theme_globalmob .faq_title[data-id="7"]').click();
        });

        $('.reinsurance .retours_remboursement').on("click touch", function () {
            $('.theme_globalmob .faq_title[data-id="6"]').click();
        });

        $(".reinsurance a").click(function () {
            window.setTimeout(function () {
                $("html, body").delay(1000).animate({
                    scrollTop: $('.theme_globalmob').offset().top - 100
                }, 1500);
            }, 500);
        });
    }

    if ($(".wish_liste_product").length) {
        $(".wish_liste_product .product_ctn").each(function () {
            $(this).find('.addToCartFromWishlist').on('click', function () {
                $(this).addClass('loading');
            });
        });
    }

    if ($('#availabilityInStore').length || $('#lightboxGlAddToList').length) {
        var start_point_as;

        $('#availabilityInStore .availabilityInStoreTitle, #lightboxGlAddToList .gl_title').on('touchstart', function (e) {
            start_point_as = e.originalEvent.touches[0].pageY;
        });

        $('#availabilityInStore .availabilityInStoreTitle, #lightboxGlAddToList .gl_title').on('touchend', function (e) {
            var endY_as = e.originalEvent.changedTouches[0].pageY;
            var deltaY_as = endY_as - start_point_as;
            var swipeThreshold_as = 10;

            if (deltaY_as > swipeThreshold_as) {
                $('#availabilityInStore').slideDown().removeClass('actif');
                $('#lightboxGlAddToList').slideDown().removeClass('actif');

                 window.setTimeout(function () {
                    $('#shade').removeClass('actif');
                    $('body').removeClass('noscroll');
                }, 300);
            }
        });
    }

    if ($('.visited_pdt_holder' || '.assos_rayon').length) {
        var all_swiper_container = $('.visited_pdt_holder, .assos_rayon').find('.swiper-slide');

        all_swiper_container.each(function () {
            $(this).find('.close_modal').append('<span class="txt txt_tdg">' + Translator.translate('close') + '</span>');
            var itm_id_tdg = $(this).attr('id');

            $(this).find('.bloc_add_color .color_achat_express, .bloc_add_color span').on('click', function () {
                openMultiShad(itm_id_tdg);
            });
        });
    }

    if ($('#products_look .associated_product').length) {
        $('#products_look .associated_product').each(function () {
            var cta_associated_product_action = $(this).find('.associated_product_action');
            var selected_value_size = $(this).find('select').val();
            var from_size_list = $('#lightboxAchatExpressV1ColorSizeMobile').find('.form_itm');

            $(this).find('select').change(function() {
                selected_value_size = $(this).val()
            });

            cta_associated_product_action.on("click", function () {
                window.setTimeout(function () {
                    var input_value = $('#lightboxAchatExpressV1ColorSizeMobile #choose_sizes .form_itm input').val();

                    $('#lightboxAchatExpressV1ColorSizeMobile #choose_sizes .form_itm').each(function () {
                        var input_value = $(this).find('input').val();
                        $(this).attr('id', 'size_val_' + input_value);
                        $('#size_val_' + selected_value_size).click();
                    });
                }, 1000);
            });
        });
    }

    if ($('body.parrainage_index').length > 0) {
        $('body.parrainage_index').css('opacity', '1');
    }

    if ($('.cart.step_1').length ) {
        $('#tunnel_right_col strong').each(function() {
            $(this).replaceWith($(this).contents());
        });
    }

    // Ajouter une classe specifique pour parrainage deconnecte
    if ($('body.parrainage_index').length > 0 && $('body.parrainage_index').find('#bloc_parrainage_not_client').length > 0) {
        $('body.parrainage_index').addClass('parrainage_disconnect');
    }

    if ($('body.product_page').length) {
        $('.prod_page_bot .price_container').appendTo('#wrapper_product_name');

        if ((!$('#wrapper_product_name .product_ecoparticipation').length && $('.prod_page_bot .product_ecoparticipation').length)) {
            $('.prod_page_bot .product_ecoparticipation').appendTo('#wrapper_product_name .price_container');
        }

        $('#prod_taille option.disabled:selected').parent('#prod_taille').addClass('no_stock');
    }
    // Init git card sections with controlled input
    if($('#buttonGiftCardContainer').length) {
        setTimeout(() => {
            $('#giftCardDeliveryMethod .btn_container').hide()
            $('#giftCardDeliveryMethod #selectBalance').attr('required', 'required');
            $("#giftCardSendByEmail").show();
            $('#giftCardSendByEmail #r_fname').attr('disabled', 'true').attr('required', 'required');
            $('#giftCardSendByEmail #s_sign').attr('disabled', 'true').attr('required', 'required');
            $('#giftCardSendByEmail #s_msgPerso').attr('disabled', 'true').attr('required', 'required');
        }, 0);
        $('#selectBalance').on('input', debounce(async function() {
            loadGiftCardSections('giftCardSendByEmail');
        }, 300));
    }

    if ($(".product_option .info")) {
        $(".product_option .info").on("click", function() {
            var lightbox = $(this).attr("data-lightbox");

            if (lightbox) {
                openMultiShad(lightbox);
            }
        });
    }

    $('.politique_confidentialite .content_sat .cookieShowLightbox').click(function() {
        $('.politique_confidentialite #cookiesLightbox').addClass('displayCookies');

        if ($('.politique_confidentialite #cookiesLightbox').has('.hideCookies')) {
            $('.politique_confidentialite #cookiesLightbox').removeClass('hideCookies').show();
        }
    });
    
    $('.politique_confidentialite #js_cookie_refuse, .politique_confidentialite #js_cookie_accept').click(function() {
        if ($('.politique_confidentialite #cookiesLightbox').has('.displayCookies')) {
            setTimeout(() => {
                $('.politique_confidentialite #cookiesLightbox').removeClass('displayCookies');
                $('.politique_confidentialite #cookiesLightbox').addClass('hideCookies').hide();
            }, 500);
        }
    });
});


// CLOSE SEARCH HEADER
function close_search() {
    if (!$(".banner_wrapper.sticky").length && !$(".banner_itm.menu_open").length && !$(".menu_overlay.active").length)  {
        $("#top_searchV2").removeClass("show");
        if($("#search_trigger").hasClass("search_triggered")) {
            $("#search_trigger").removeClass("search_triggered");
        }
    } else {
        $("#search_homeV2").focus();
    }
}

// Search Behavior on menu toggle
function toggleMenuExtra() {

    if (!$("#top_searchV2").hasClass('show')) {
        $("#top_searchV2").addClass('show');
    } else {
        if (!$(".banner_wrapper.sticky").length && !$(".banner_itm.menu_open").length && !$(".menu_overlay.active").length) {
            $("#top_searchV2").removeClass('show');
        }
    }
}

// Search Behavior on account menu toggle - uses same logic as menu toggle
function toggleAccountMenuExtra() {
    toggleMenuExtra();
}

function moveTopSearchV2ToHeader() {
    // Move the search bar V2 below the search bar V1 in the header tag
    setTimeout(function() {
        $('#top_searchV2').prependTo('header .nav_right');
    }, 2000);
}

// Footer reassurance
function checkWordAfterHash() {
    var hash = window.location.hash;
    var faq_anchor = hash.slice(1);

    switch (faq_anchor) {
        case "paiement":
            $('.faq_title[data-id="4"]').click();
            break;
        case "payment":
            $('.faq_title[data-id="4"]').click();
            break;
        case "livraisons_et_retours":
            $('.faq_title[data-id="5"]').click();
            break;
        case "deliveries_and_returns":
            $('.faq_title[data-id="5"]').click();
            break;
        case "service_client":
            $('.faq_title[data-id="7"]').click();
            break;
        case "customer_service":
            $('.faq_title[data-id="7"]').click();
            break;
        case "retours_et_remboursement":
            $('.faq_title[data-id="6"]').click();
            break;
        case "returns_and_refunds":
            $('.faq_title[data-id="6"]').click();
            break;
        default:
            $('.faq_title[data-id="1"]').click();
            break;
    }
}

function hideModalAction(evt) {

    var shade = $('#shade');
    var modal = $(".cart_item_actions");
    if (typeof evt !== "undefined") evt.preventDefault();


    shade.off('touchend', modBoxClose).removeClass('visible');
    modal.slideUp();
}

// Toggles filters submenu visibility
function toggleFiltersMenu() {

    var open = $(this).closest('li').siblings('li').find('legend.open').get(0);

    this.classList.toggle('open');

    if (this.classList.contains('open')) {
        $(this).next('.filter_options').slideDown(400);
    } else {
        $(this).next('.filter_options').slideUp(400);
    }
}

function toggleFilters() {

    if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        $(this).find('.selected_options').slideUp(200);
    } else {
        $(this).addClass('active');
        $(this).find('.selected_options').slideDown(200);
    }
}

// _app override / Common views between Desktop and Mobile
function changeQte(type) {

    var types = ['plus', 'less'],
        item, loader, qtty, prodId;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);
        prodId = this.getValue('item_prod_id', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url: path_relative_root + create_link("order_basket"),
            type: 'post',
            data: {
                action: 'del',
                panierId: item,
                cad: 'non',
                pointcad: 0,
                qteProd: qtty,
                change_qte: true
            },
            cache: false,
            success: function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html();
                if (typeof wShop !== "undefined" && wShop.$refs.wCouponForm !== "undefined") {
                    wShop.$refs.wCouponForm.reloadBloc += 1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                $('#cart_btn').data('cart', response);

                /**
                 * Move out of stock products to the top of the cart
                 */

                updateCartElements();
                cart_switch_view();

                if (typeof dataLayer_v3 !== 'undefined' && typeof dl_v3_impressions !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && type == 'less') {
                    DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, prodId, "remove_from_cart");
                }

                if (typeof dataLayer_v3 !== 'undefined' && typeof dl_v3_impressions !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && type == 'plus') {
                    DataLayerTrigger.cart_product_action(dl_v3_impressions, dlv3_action_fields, prodId, "add_to_cart");
                }
            }
        });
    }
}

function updateCartElements() {
    if ($(".basket_gondole .swiper-slide.item_container").length > 1 && $(".basket_gondole .item_wrapper").length < 1) {
        $(".basket_gondole .swiper-slide.item_container").wrapAll("<div class='item_wrapper'></div>");
    }

    var qty = 0;
    if ($(".product_line_basket.cart_product_line").length) {
        $.each($(".product_line_basket.cart_product_line"), function(index, elt) {
            if (elt.querySelector(".selected_qte")) {
                qty += parseInt(elt.querySelector(".selected_qte").value);
            } else {
                // service
                qty += 1;
            }
            if (index == $(".product_line_basket.cart_product_line").length - 1) {
                $("#cart_btn").attr("data-cart", qty);
                if ($(".toggleCartTypeBtn.basket").length) {
                    $(".toggleCartTypeBtn.basket .nb_products").text(qty);
                }
            }
        });
    } else {
        $(".banner_itm").removeClass("count");
        $("#cart_btn").attr("data-cart", '');
        if ($(".toggleCartTypeBtn.basket").length) {
            $(".toggleCartTypeBtn.basket").html('<span class="toggleBtnLabel">' + Translator.translate('mon_panier') + '</span>');
        }
    }

    if ($('.cart.step_1').length ) {
        $('#tunnel_right_col strong').each(function() {
            $(this).replaceWith($(this).contents());
        });
    }

    wording_faq_cart();
}

function cart_switch_view() {
    /* Switch between cart and wishlist view in basket */
    if ($('.toggleCartTypeBtn').length) {

        let toggleCart = $('.toggleCartTypeBtn');
        let cartContent = $('.cart_main_table.basket');
        let wishContent = $('.cart_main_table.wishlist');
        let cartSummary = $('#tunnel_right_col');

        toggleCart.on('click', function () {

            toggleCart.removeClass('actif');
            $(this).addClass('actif');
            $('.cart_main_table').removeClass('displayed');

            if ($(this).hasClass('basket')) {

                cartContent.addClass('displayed');
                cartSummary.removeClass('noDisplay');
            } else {

                wishContent.addClass('displayed');
                cartSummary.addClass('noDisplay');
            }
        });
    }
}

// STORE LOCATOR
function creerLigneListe(cpt, id, nom, adresse, ville, cp, pays, currently_open, horaire_today, distance, marqueur, picture_filtre, magasinId, stock, stockStatus, stock_quantity, clientId, favorite, beContacted, flag_available, url, shop_filter, telephone, query, lat, lng) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement('li');
    li.id = id;
    li.dataset.lng = lng;
    li.dataset.lat = lat;

    li.className = 'elem-list-store';

    if (lat != "" && lng != "" && !$('body.product_page').length) {
        li.setAttribute("onclick", "geolocGoto('" + lat + "', '" + lng + "')");
    }

    if (favorite == "1") {

        choose_fav = Translator.translate('actual_favorite_store');
    } else {

        choose_fav = Translator.translate('choose_as_favorite_store');
    }

    /***** Product stock infos *****/
    if (stock_quantity == 'undefined' || stock_quantity == '') {
        stock_status_msg = 'unknow_stock';
    } else {
        var in_stock = (stock_quantity >= 6 && stock_quantity <= 9999);
        var limited_stock = (stock_quantity >= 1 && stock_quantity <= 5);
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = '';
        if (in_stock) {
            stock_status_msg = 'in_stock';
        }
        if (limited_stock) {
            stock_status_msg = 'limited_stock';
        }
        if (not_in_stock) {
            stock_status_msg = 'not_in_stock';
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = 'to_be_confirmed_stock';
        }
        if (can_be_ordered_stock) {
            stock_status_msg = 'can_be_ordered_stock';
        }
    }

    var info_horaire_today = !horaire_today ? Translator.translate("store_closed") : horaire_today;
    var class_horaire_today = currently_open ? 'store_open' : 'store_closed';
    var ligneListe =
        '<div class="elem_list_contents">' +
        '<div class="nom_store">';
    if (distance) {
        ligneListe += '<h2 class="title">' + nom + '<p class="distance">' + '(' + distance + 'km)' + '</p></h2>';
    } else {
        ligneListe += '<h2 class="title">' + nom + '</h2>';
    }
    ligneListe += '</div>' +
        '<div class="content_store">'

    if ($('body.product_page').length) {
        ligneListe += `<div class="detail_store" onclick="location.href = '${path_relative_root + url}'">`
    } else {
        ligneListe += '<div class="detail_store">'
    }
    ligneListe += '<div class="col-1">' +
        '<p><span class="rue_store">' + adresse + '</span>' +
        '<span class="ville_store">' + cp + ' ' + ville.charAt(0).toUpperCase() + ville.slice(1).toLowerCase() + ' - ' + '<span class="pays_store">' + pays + '</span>' + '</span></p>';

    if (telephone != '' && telephone != 'undefined') {
        ligneListe += '<a href="tel:' + telephone + '" class="tel_store">' + telephone + '</a>';
    }

    ligneListe += '</div>';

    var is_page_product = $('body.product_page').length == 1;

    if ((info_horaire_today !== '' && currently_open) || (!currently_open && horaire_today)) {
        ligneListe += '<div class="col-2"><p class="horaire_today puce_' + class_horaire_today + '">' + info_horaire_today + '</p></div>';
    }

    ligneListe += '</div>';
    if (document.getElementById('produit_id')) {

        /***** Product stock infos msg *****/
        if (stock != 'undefined') {
            ligneListe += '<p class="stock_status ' + stock_status_msg + '">' + Translator.translate(stock_status_msg + '_msg') + '</p>';
        }
    }

    if (is_page_product) {

        if (beContacted == 1) {
            if ($('body.product_page.mobile').length) {
                ligneListe += '<div class="availableTxt store_more" onclick="handleChangeMobile(' + id + ')">' + '<span>' + txt_available_mag + '</span>' + '</div>' + '</div>' + '</div>';
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe += '<div class="availableTxt store_more" onclick="handleChange(\'' + id + '\',\'' + nom_mag + '\')">' + '<span>' + txt_available_mag + '</span>' + '</div>' + '</div>' + '</div>';
            }
        } else {
            ligneListe += '</div>' + '</div>';
        }
    }

    if (!is_page_product) {
        ligneListe += '<div class="store_more"><a onclick="createDataLayer(' + id + ')" href="' + path_relative_root + url + '">' + Translator.translate('afficher_horaires') + '</a>'
            + '</div>';
    }

    ligneListe += '</div>' + '</div>' + '</div>';

    li.innerHTML = ligneListe;

    li.addEventListener('click', function () {
        google.maps.event.trigger(marqueur, 'click');
    });

    return li;
}

function creerMarqueur(point, nom, adresse, ville, cp, pays, horaire, telephone, email, site, image_mag, type_mag, cpt, magasin_id, url, distance, currently_open, horaire_today) {
    var img = path_relative_root + "img/maps/marker.png";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(img,
        // This marker is 20 pixels wide by 32 pixels tall.
        new google.maps.Size(45, 50),
        // The origin for this image is 0,0.
        new google.maps.Point(0, 0));

    // The anchor for this image is the base of the flagpole at 0,32.
    //new google.maps.Point(10, 26));
    var shadow = new google.maps.MarkerImage(path_relative_root + 'img/indic_carte_ombre.png',
        // The shadow image is larger in the horizontal dimension
        // while the position and offset are the same as for the main image.
        new google.maps.Size(39, 53),
        new google.maps.Point(0, 0),
        new google.maps.Point(10, 0));
    // Shapes define the clickable region of the icon.
    // The type defines an HTML <area> element 'poly' which
    // traces out a polygon as a series of X,Y points. The final
    // coordinate closes the poly by connecting to the first
    // coordinate.
    var marqueur = new google.maps.Marker({
        position: point,
        icon: image
    });

    marqueur.set("magasin_id", magasin_id);
    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    distance = Math.round(distance);
    var info_horaire_today = !horaire_today ? '' : horaire_today;
    var class_horaire_today = currently_open ? 'store_open' : 'store_close';

    if (distance) {
        var ligneListe = '<div class="info_popup"><div class="name"><a href="' + url + '">' + nom + '</a><span class="dist">(' + distance + ' km)</span>';
    } else {
        var ligneListe = '<div class="info_popup"><div class="name"><a href="' + url + '">' + nom + '</a></div>';
    }

    ligneListe += '<div class="adresse">' + adresse.toLowerCase() + '<br />' + cp + ' ' + ville.toLowerCase() + '<span class="pays"><br />' + pays.toLowerCase() + '</span></div>';

    if ((info_horaire_today !== '' && currently_open) || (!currently_open && horaire_today)) {
        ligneListe += '<div class="horaire_today"><span class="puce ' + class_horaire_today + '"></span> ' + info_horaire_today + '</div>';
    }

    // Tooltip more infos & go to store link
    ligneListe += `<div class="store_localisation_container">
        <div class="btn_voir_magasin go_to_store"><a class="geolocalisation_link" target="_blank" href="https://maps.google.fr/maps?daddr=${point.lat()},${point.lng()}">${Translator.translate('go_to_store')}</a></div>
        <div class="btn_voir_magasin"><a href="${url}">${Translator.translate('voir_magasin')}</a></div>
        </div>`
    // Add marker image inside tooltip
    ligneListe += `<div class="store_marker"><img src="${image.url}"/></div>`;

    google.maps.event.addListener(marqueur, "click", function () {
        // selected store coordinates
        var storePosition = new google.maps.LatLng(point.lat(), point.lng());
        // center map on marker
        maCarte.setCenter(storePosition);
        // map animation on marker switch
        maCarte.panTo(storePosition);

        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });

    var detail_store_panel = document.getElementById("store_id_dataLayer");
    detail_store_panel.textContent = magasin_id

    markersArray.push(marqueur);

    return marqueur;
}

function openMultiShad(id) {
    $("#" + id).addClass('actif');

    if (!$("#shade").hasClass('actif')) {
        $("#shade").addClass('actif');
    }

    if (id != 'lightbox_preco') {
        $("#shade").on("click", function () {
            closeMultiShad(id);
        });
    }
}

function closeMultiShad(id) {
    $("#" + id).removeClass('actif open');
    $("#shade").removeClass('forFilters');

    setTimeout(function () {
        $("#shade").removeClass('actif');
    }, 200);
}

function faqinit(array_questions) {
    $(document).on('change keyup focus', '#faq_search', function () {
        $('#theme_page .theme').each(function () {
            $(this).removeClass('active');
        });

        showTabFaq();

        var search = $(this).val();

        if (search && search.length >= 3) {

            $('.search_results li').show().removeClass("hasMatch");
            $('#theme_global').removeClass('actif');
            var array_match = [];

            // On affiche uniquement celles qui correspondent à la recherche
            $.each(array_questions, function (i, val) {

                val["nom"] = val["nom"].toUpperCase();
                var match = val["nom"].match(search.toUpperCase());

                if (match) {
                    array_match.push(match);
                    $('.search_results').show();
                    $('li#brand_' + val['id']).addClass("hasMatch");
                }

            });

            $('.brand_item:not(.hasMatch)').hide();

            if (array_match.length == 0) {
                $('.search_results').hide();
                $('#theme_global').addClass('actif');
            }
        } else {
            $('.search_results').hide();
            $('.search_results li').show().removeClass("hasMatch");
            $('#theme_global').addClass('actif');
        }
    });

    var click_in_process = false;

    $('.search_results').mousedown(function () {
        click_in_process = true;
    });

    $('.search_results').mouseup(function () {
        click_in_process = false;
        $('#faq_search').focus();
    });

    $('#theme_global .theme').on('click', function () {
        var id = $(this).data('id');
        var theme = $('#theme_page');
        var retour = $('#reset_faq');
        $('#theme_global').removeClass('actif');
        $('.title_detail_sub').css('display', 'none');
        $('#theme_global .theme').not($(this)).removeClass('actif');
        $(this).toggleClass('actif');
        retour.addClass('actif');
        theme.addClass('actif');
        theme.show();
        showTabFaq(id, 1)
    });

    $('#reset_faq').on('click', function () {
        var theme = $('#theme_page');
        var retour = $('#reset_faq');
        $('#theme_global').addClass('actif');
        theme.removeClass('actif');
        theme.hide();
        retour.removeClass('actif');
        showTabFaq();
    });

    $('#theme_page .theme').on('click', function () {
        var id = $(this).data('id');
        showTabFaq(id, 1);

        if (!$(this).hasClass('active')) {
            $('#theme_page .theme').each(function () {
                $(this).removeClass('active');
            });
            $(this).addClass('active');
        } else {
            $(this).removeClass('active');
        }
    });
}

function showTabFaq(node_id, level, question_id) {
    if (question_id !== null && question_id !== undefined) {
        //show answer
        $(".faq_reponse:not(.faq_reponse_" + question_id + ")").slideUp(
            "fast",
            function () {
                $(this).addClass("cache");
            }
        );

        $(".faq_reponse_" + question_id).slideToggle("slow", function () {
            $(this).toggleClass("cache");
        });

        $(".faq_question:not(.faq_question_" + question_id + ")").removeClass(
            "active"
        );
        $(".faq_question_" + question_id).toggleClass("active");

    } else {
        var parent_id = $(".faq_theme_" + node_id).data("parent");

        //active
        $(".faq_element:not(.faq_theme_" + node_id + ")").removeClass("active");
        $(".faq_theme_" + node_id).toggleClass("active");
        $(".faq_theme_" + parent_id).toggleClass("active");

        //show questions
        $(
            ".faq_questions:not(.faq_questions_" +
            node_id +
            ", .faq_questions_" +
            parent_id +
            " )"
        ).slideUp("fast", function () {
            $(this).addClass("cache");
        });

        $(".faq_questions_" + node_id).slideToggle("slow", function () {
            $(this).toggleClass("cache");
        });

        // Hide other node of same level
        $(".faq_level_" + level + ":not(.faq_theme_" + node_id + ")").each(
            function (idx) {
                var id = $(this).data("id");
                $(".faq_level_parent_" + id).slideUp("fast", function () {
                    $(".faq_level_parent_" + id)
                        .addClass("cache")
                        .trigger("classChange");
                });
            }
        );

        // Show/hide child of selected node
        $(".faq_level_parent_" + node_id).slideToggle("slow", function () {
            $(".faq_level_parent_" + node_id)
                .toggleClass("cache")
                .trigger("classChange");
        });

        $(".faq_element").on("classChange", function () {
            var id = $(this).data("id");

            if ($(this).hasClass("cache")) {
                $(".faq_level_parent_" + id)
                    .addClass("cache")
                    .trigger("classChange");
            }
        });
    }
}

function researchFaq(node_id, level, question_id) {

    $('.search_results').hide();
    $('.search_results li').show().removeClass("hasMatch");

    if ($('#theme_global').hasClass("actif")) {
        $('#theme_global').removeClass('actif');
        $('#theme_page').addClass('actif');
    }

    if (!$(".faq_theme_" + node_id + "").hasClass("active")) {
        showTabFaq(node_id, level);
    }

    $(".theme_globalmob .faq_title").each(function () {
        var getDataId = $(this).data("id");

        if (getDataId === node_id) {
            $(".theme_globalmob .faq_title").removeClass("active");
            $(this).addClass("active");

            const activeDiv = $(".theme_globalmob .faq_title.active");
            if (activeDiv.length) {
                // Scroll to the center of the active div within the container
                const container = $('.theme_globalmob');
                const activeDivLeft = activeDiv.offset().left;
                const containerLeft = container.offset().left;
                const scrollPosition = activeDivLeft - containerLeft - (container.width() / 2) + (activeDiv.width() / 2);
                container.scrollLeft(scrollPosition);
            }
        }
    });
}

function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop, fromPagination = false) {

    if ($('.see_all')) {
        $('.see_all').addClass('loading');
    }

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filters_form'),
        output = {},
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        nb_product = nb_product || parseFloat($('#nb_prod').val()),
        type = form.getValue("type"),
        idObj = idObj || parseFloat($('#idObj').val()),
        startPage = parseInt($('#page_start').val());

    output = triggerFilter(
        page,
        type,
        idObj,
        nb_product,
        "filters_form",
        fromPagination
    );

    let one_product = $("#oneProduct").hasClass("actif") ? true : false;

    if (isNaN(this.page.value)) {
        this.page.value = 0;
    }

    if ($('#is_marque').val()) {
        output.is_marque = $('#is_marque').val();
        output.promo = $('#brand_id').val();
    }

    // Si le prix existe
    if ($("input[name=price_min]").length > 0) {
        // Récupération du prix
        output.prix = $("#amount1").val() + "~" + $("#amount2").val();
    }

    if ($("input[name=tri]:checked").val() != undefined) {
        output.tri = $("input[name=tri]:checked").val();
    }

    if (bloc_prod_xhr) {
        bloc_prod_xhr.abort();
    }

    if (output.page > 0) {
        output.page = $('#scroll_items .item_container:not(.push)').length;
    }
    output.handle_push = 'false';

    bloc_prod_xhr = $.ajax({
        url: path_relative_root + 'ajax_reload_products.php',
        type: 'get',
        data: output,
        success: function (res) {

            var old_elm = document.querySelector('.list_item');
            var content, itm_length;

            if (btn && btn.nextElementSibling && (btn.nextElementSibling.classList.contains('btn_tunnel') || btn.nextElementSibling.classList.contains('loader'))) {
                btn.nextElementSibling.style.display = 'none';
            }

            res = res.split('><><');

            content = res[0].trim();

            itm_length = parseFloat(res[1]);

            let canonical = res[5];
            let prev = res[6];
            let next = res[7];

            $("link[rel^='canonical']").attr("href", path_root + canonical);
            $("link[rel^='alternate'][hreflang^='fr-fr']").attr("href", path_root + canonical);

            $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

            let hrefPrev = $("link[rel^='prev']");
            let hrefNext = $("link[rel^='next']");
            let commentPrev = $('#href_prev');
            let commentNext = $('#href_next');

            changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
            changeSeoElement(next, hrefNext, commentNext, 'next');

            if ($('.rayonCategSlider').length && output.object_id) {

                $('.rayonCategSlider .menuitem').removeClass('actif');
                $(".menuitem[data-idobj='" + idObj + "']").addClass("actif");

                var rayonInitialSlide;

                $('.rayonCategSlider .swiper-slide').each(function (i, obj) {
                    if ($(obj).hasClass('actif')) {
                        rayonInitialSlide = i;
                    }
                });

                rayonCategSlider.slideTo(rayonInitialSlide);
            } else {
                $('.rayonCategSlider .menuitem').removeClass('actif');
            }

            if (content !== '') {
                if (!isNaN(page)) {
                    old_elm.setAttribute('data-p', (page * nb_product));
                }

                if (!fromScrollBottom && !fromScrollTop) {
                    old_elm.innerHTML = "";
                }

                document.getElementById('nbr_items').innerHTML = itm_length;
                document.getElementById('totalElems').value = itm_length;
                let nbProductLabel = (itm_length > 1 ? Translator.translate('2_product') : Translator.translate('one_product'));
                $('.seo_nb_product').html(itm_length + ' ' + nbProductLabel);

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item_container:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top - curOffset);
                    //L'inverse pour le bas
                } else {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (one_product) {
                    $('div.item_container').addClass('full');
                }

                if (parseFloat($('#filters_menu').css('right')) === 0) {
                    toggleMenu('filters', 'right');
                }

                if (window.lazyload || typeof lazyload != 'undefined') {
                    lazyload.init();
                }

                initDailymotionVideos();
            }
        },
        complete: function () {
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');

            if (!fromScrollBottom && !fromScrollTop) {
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
                setTimeout(function () {
                    $("html, body").animate({
                        scrollTop: ($(window).scrollTop() + 10)
                    }, 200);
                }, 250);
            }

            if (fromScrollTop || fromScrollBottom) {
                $('#is_loading').val('0');

                /**
                 * Ajout d'une min-height sur les blocs image rayon. Permet d'éviter l'effet de
                 * clignotement de la page (du au lazyload) si on scrolle depuis le bas.
                 */

                if ($('body.category').length) {
                    var origin = $('.item_container:not(.push)').find('img.watched'),
                        target = $('span.ill_img'),
                        targetHeight = origin.height();
                }
            }

            if (fromScrollBottom && !fromScrollTop) {
                $("#page_start").val(startPage + 1);

                if ($("#page_start").val() >= 1) {
                    $("#initial_page").val(page);
                }
            }

            /**
             * UPDATE BOTTOM GAUGE WIDTH NB PRODUCTS LOADED / NB PRODUCTS TOTAL + BTN INITIAL STATE (No loader)
             */
            var productLoaded = $('#scroll_items .reloadProduct').length;
            var total_element = document.getElementById('totalElems').value;
            var percentLoaded = (productLoaded * 100 / parseInt(output.nb_tot_prod));

            if (productLoaded > output.nb_tot_prod) {
                $('.nbProdDisplayed').html(Translator.translate('pagerTxt', productLoaded, productLoaded));
            } else {
                $('.nbProdDisplayed').html(Translator.translate('pagerTxt', productLoaded, parseInt(output.nb_tot_prod)));
            }

            $('.loadedGauge').html('<span style="width: ' + percentLoaded + '%;"></span>');

            $('.loader_scroll').removeClass('loading');
            $('.see_all_product').removeClass('loading');

            if (productLoaded >= total_element) {
                $('.loader_scroll').hide();
                $('.see_all_product').hide();
            } else {
                $('.loader_scroll').show();
                $('.see_all_product').show();
            }

            initDailymotionVideos();
        }
    });
}

function switchToggleCartpos() {
    if ($('.checkout').length) {
        $('.total_produit.toggle').insertBefore($('.bank_left_side'));
        $('.total_produit_dropdown').insertBefore($('.bank_left_side'));
    } else {
        $('.total_produit.toggle').insertBefore($('.left_side.elem_tunnel'));
        $('.total_produit_dropdown').insertBefore($('.left_side.elem_tunnel'));
    }
}

function switchAddrRecapCartpos() {
    if ($('.checkout').length) {
        $('.wrapper_payment').insertBefore($('.bank_left_side'));
    } else {
        $('#blocs_address').insertBefore('.left_side.elem_tunnel');
    }
}

function copySponsorCode(txt) {
    // Copy the text inside the text field
    navigator.clipboard.writeText(txt);
    // animation to the copy
    $(this).html(Translator.translate("parrainage_copied_code"));
}

function initItemSlider(isToggled) {

    if ($('.prodItemSlider, .searchItemSlider').length) {

        var swiperLazy = is_lazy_load ? false : true; // is_lazy_load defined in _app -> views_mobile_new -> main.php

        if (isToggled == true) { // Toggle view is triggerred

            setTimeout(function () {

                $('.prodItemSlider, .searchItemSlider').each(function () {

                    if ($(this).hasClass('initialized')) {
                        this.swiper.update();
                        this.swiper.slideTo(1);
                    }
                });
            }, 200); // 300 equals the css transition timing defined on .item_container
        } else { // From page load and scroll

            $(".item_container").each(function (e) {
                var this_item_rayon = $(this).attr('id');

                if (!$(this).find('.prodItemSlider').hasClass('initialized') && $(this).find('.prodItemSlider .swiper-slide').length > 1) {
                    var swiper_rayon_mobile_each = new Swiper('#' + this_item_rayon + ' .prodItemSlider', {
                        roundLengths: true,
                        slidesPerView: 1,
                        loop: true,
                        preloadImages: false,
                        lazyLoading: swiperLazy,
                        observer: true,
                        observeParents: true,
                        pagination: '#' + this_item_rayon + ' .itemSliderPagination',
                    });

                    $(this).find('.prodItemSlider').addClass('initialized');
                }
            });
        }
    }
}

// Initialisation des JS pour la recherche V2
function triggerFunctionOnSearchEnd() {
    //Init des swiper dans le cas des visuel multi des produit (MOBILE_MULTI_VISUALS)
    initItemSlider(false);

    if ($(".bloc_sub_categ").length) {
        if ($(".bloc_sub_categ .swiper-slide").length > 1) {
            const bloc_sub_categ = new Swiper($(".bloc_sub_categ"), {
                slidesPerView: "auto",
                spaceBetween: 30,
            });
        }

        setTimeout(function () {
            $(".bloc_sub_categ").addClass("appear");
        }, 500);
    }
}

function toggleViewMobile() {

    $('div.toggleNumObjects span, div.toggleNumObjects button').toggleClass('actif');
    if ($("#oneProduct").hasClass('actif')) {
        $('.twoProducts').removeClass('actif');
        $('div.item').addClass('full');
        createCookie('nb_produits', 1, 1);
    } else {
        $('div.item').removeClass('full');
        $('.oneProduct').removeClass('actif');
        createCookie('nb_produits', 2);
    }

    initItemSlider(true);
}

function show_sizeguide_box() {
    $('#sizeguideBox').show().addClass('actif');
    $('body').addClass('noscroll');
    $('#shade').addClass('forFilters');

    switch_gdt_txt_swiper();
}

function fadeTaille(id, nbelem) {
    $('.switch_gdt_tables td').removeClass('actif prev_actif_td');
    $('.switch_gdt_tables tr').removeClass('active_tr inactive');

    for (var i = 1; i <= nbelem; i++) {

        if (id != i && $("#content_quide_" + i).css('display') == 'block') {

            $("#content_quide_" + i).slideUp(400).removeClass('actif');
            $("#intitule_quide_" + i).removeClass('actif');
        }
    }

    if ($("#content_quide_" + id).css('display') != 'block') {

        $("#content_quide_" + id).slideDown(400);
        $("#content_quide_" + id).addClass('actif');
        $("#intitule_quide_" + id).addClass('actif');
    }
}

function addAllWpToCart() {
    var i = 0;
    var wishlistProductId = "";
    var wp = $(".addToCartFromWishlist");
    for (i = 0; i < wp.length; i++) {
        wishlistProductId += "," + wp[i].getAttribute('data-id');
    }
    addWpToCart(wishlistProductId);
}

function addWpToCart(wishlistProductId) {
    if (typeof wishlistProductId != "string") {
        wishlistProductId = wishlistProductId.toString();
    }
    var selectedProducts = wishlistProductId.split(',');
    $.post(path_relative_root + "ajax_add_panier_wishlist.php", {data: wishlistProductId}, function (resp) {
        var data_layer_products = window.data_layer_products;
        var dtl_products = [];
        if (resp == "ok") {
            if (Object.keys(data_layer_products).length > 0) {
                for (const property in data_layer_products) {
                    if (selectedProducts.indexOf(property) !== -1) {
                        dtl_products.push(data_layer_products[property])
                    }
                }
            }

            if (dtl_products.length > 0) {
                var dataLayer = window.dataLayer || []
                dataLayer.push({
                    "event": "addToCart",
                    "ecommerce": {
                        'currencyCode': 'window.siteCurrency',
                        "add": {
                            "products": dtl_products
                        }
                    }
                })

            }

            if (typeof dataLayer_v3 !== 'undefined' && typeof dl_v3_impressions !== 'undefined' && typeof dlv3_action_fields !== 'undefined') {
                var impressions = JSON.parse(dl_v3_impressions);
                var actionField = JSON.parse(dlv3_action_fields);
                var selectedItem = [];
                for (var impression in impressions) {
                    if (impressions[impression]['wishlist_product_id'] == wishlistProductId) {
                        selectedItem.push(impressions[impression]);
                        actionField['revenue'] = impressions[impression]['price'];
                        actionField['event_id'] = '';
                        actionField['cart_id'] = '';
                        DataLayerTrigger.addToCart(selectedItem, actionField);
                    }
                }
            }
            document.location.href = path_relative_root + create_link('order_basket');
        } else {
            $('.lightbox .form_submit .button span').html(Translator.translate("close"));
        }
    });
}

function lightboxAlertStock(productId, type_alert, produit_page_id) {

    if ($("body").hasClass('product_page')) {
        if (typeof (produit_page_id) == 'undefined') {
            var produit_id = $('input[name="produit_id"]').val();
        } else {
            var produit_id = produit_page_id;
        }

        var $form, data;
        if (!$("#lightbox_stock_alert").hasClass("open")) {
            $form = $('#product_selects');
            data = $form.serialize();
            // Donc dans le cas où on ne le trouve pas on cherche sur les id des couleur et de la taille
            if ($form.length == 0) {
                // Si on a pas de product_selects alors on va chercher les valeur qu'il nous manque (couleur et taille)
                data += '&tailleProd=' + $('#prod_taille').val();
                data += '&couleurProd=' + $('#prod_couleur').val();
            }
        } else {
            $form = $('#form_colisage');
            data = $form.serialize();
        }

        type = 'couleur';
        var $input = $('#' + productId, $form); // ID Champs Clique
        var $from = productId.split('_')[0];
        var $hidden = $('#' + type + 'Prod', $form);

        var $select = $('#ligne_' + type);
        var $list_inputs = $('.select_input', $select);
        var $list_labels = $('.select_label', $select);

        // Les info supplémentaire
        data += '&produit_id=' + produit_id;
        data += '&from=nothing';
        data += '&type_alert=' + type_alert;

        $.ajax({
            type: 'post',
            url: path_relative_root + 'ajax_lightbox_alert.php',
            data: data,
            success: function (response) {
                try {
                    response = $.parseJSON(response);
                } catch (e) {
                    console.error('parseJSON');
                    return;
                }

                if (response.success == 'ok') {
                    var datas = response.datas;
                    var $html = $(datas.html);
                    $('#lightbox_stock_alert').html($html);
                } else {
                    $('#btn_error').fadeIn();
                }
            },
            complete: function () {
                $('#bloc_btn_loader, .loader').fadeOut(300);
                if (typeof (produit_page_id) == 'undefined') {
                    modBoxOpen.call(document.getElementById('btn_add_alert'));
                } else {
                    modBoxOpen.call(document.getElementById('btn_add_alert_' + produit_id));
                }
            }
        });
    } else {
        if (typeof productId === "undefined") {
            productId = '';
        } else {
            productId = '_' + productId;
        }
        var $taille_id = $("#tailleProd").val(),
            $couleur_id = $("#couleurProd").val();

        if ($taille_id == '') {
            if (!$('#sizeerror').is(':visible')) {
                $('#sizeerror').slideDown();
            }
            return;
        } else if ($couleur_id == '') {
            alert(Translator.translate('js_error_couleur'));
            return;
        }

        var isFormVisible = $('.bloc_add_alert_form').is(':visible');
        var isMessageVisible = $('.bloc_add_alert_confirmation').is(':visible');
        var isErrorVisible = $('.bloc_add_alert_erreur').is(':visible');

        if (isFormVisible || isMessageVisible || isErrorVisible) {

            if (isFormVisible) {

                $('#btn_alert_stock' + productId).slideDown('fast');
            }

            if (isMessageVisible) {

                $('.bloc_add_alert_confirmation').slideUp('fast');
            }

            if (isErrorVisible) {
                $('.bloc_add_alert_erreur').slideUp('fast');
            }
        } else {

            $('#bloc_add_alert_form' + productId).slideDown('fast');
            $('#btn_alert_stock' + productId).slideUp('fast');
        }
    }
}

function removeWp(from)
{
    var current_elem = $(this);
    var product_id = current_elem.data("prod");
    var color_id = current_elem.data("color");
    var size_id = current_elem.data("size");

    var datas = {
        remove: 1,
        product_id: product_id,
        color_id: color_id,
        size_id: size_id
    }

    $.post(path_relative_root + 'ajax_remove_from_wishlist.php', datas, function (data) {

        if (typeof from !== "undefined" && from == "wishlist") {
            location.reload();
        } else {
            var nr;
            if (data.result == undefined) {
                data.result = $('.wish_liste_product .product_ctn').length;

            } else {
                nr = data.result.wishlistLength;
            }

            if (nr == 0) {
                $("#wishlist_top").removeClass('hasItem');
                $(".item.wishlist").hide();
            }

            // MAJ products in rayon
            if ($('a[data-productid = "' + product_id + '"]').length > 0) {
                $('a[data-productid = "' + product_id + '"]').removeClass("existToWishlistButton");
                $('a[data-productid = "' + product_id + '"]').data('wishlistproductid', '');
                $('a[data-productid = "' + product_id + '"]').attr('data-wishlistproductid', '');
            }

            var response_html = ajax_file(path_relative_root + 'ajax_show_wishlist.php');
            $("#wishlist_top").html(response_html);

            $(current_elem.parents('.product-link')).fadeOut(100, function () {

                current_elem.parents('.product-link').remove();
            });
        }
    });
}

function hidePopinColorSize() {
    $("#shade").removeClass("visible isExpress");
    $(".rollover.achat_express").hide();
    $(".rollover.achat_express").find(".ligne_form").removeClass('actif');
    $('body').removeClass('noscroll');
}

function showPopinColorSize(type) {
    var size_selected_select_option = $(this).find("option:checked").attr("value");
    var size_selected_popup_size = $(this).parents(".displayTwo").find(".achat_express.size .rollover_left .form_itm.size");
    let idProduit = $(this).find("option:checked").attr("data-produit_id");
    let color, size = null;

    $("#shade").addClass("visible isExpress");
    $(".rollover.achat_express." + type).show();
    $(".rollover.achat_express." + type).find(".ligne_form").addClass('actif');
    $('body').addClass('noscroll');

    if ($(this).hasClass("size")) {
        $(this).blur();
    } else {
        $(this).find('#prod_couleur').blur();
    }

    size_selected_popup_size.each(function() {
        if ($(this).attr("data-sizeid") === size_selected_select_option) {
            size_selected_popup_size.find("label").removeClass("selected");
            $(this).find("label").addClass("selected");
        }
    });
}

function changeColorMobile() {
    var colorId = $(this).data('colorid');
    $("#prod_couleur").val(colorId);
    loadProduct.call(this, 'couleur');
}

function changeSizeMobile(sizeGrouped) {
    let sizeId = $(this).data('sizeid');
    let productId = $(this).data('produitid');
    $("#prod_taille").val(sizeId);

    if ($("#prod_taille").hasClass("error")) {
        $("#prod_taille").removeClass("error");
    }

    if (sizeGrouped) {
        loadProduct.call(this, 'taille');
    } else {
        onAttrSelect(parseFloat(productId));
    }
}

function searchCategoryFilter() {
    window.setTimeout(function () {
        $('.tg-module .home-module-title').click(function () {
            var module_tg_id = $(this).closest(".tg-module").attr('id');
            var tg_id = $(this).attr('id');
            var tg_title_id = tg_id.replace('-title', '');
            $("#" + module_tg_id + " .home-module-title, #" + module_tg_id + " .home-module-tg").removeClass("selected");
            $("#" + tg_id).addClass("selected");
            $("#" + tg_title_id).addClass("selected");
        });
    }, 500);
}

function closeSendWishlistLightBox() {
    var backBtn = $("#content_sendwishlistbox > .w-form-line.w-submit button");
    if (backBtn.length) {
        backBtn.click()
    } else {
        closeMultiShad('sendwishlistbox')
    }
}

function showNumRetourEtiquette(idOrder, multiTransp, parentPath, idOrderTransp, idTransporteur) {

    $("#shade").addClass('actif');
    $("#popup_numero_retour_" + idOrder).addClass('open actif');

    $('.return_products_form').find('table').each(function () {
        var return_products_form_select_box_quantity = $(this).find('.return_quantity').val();
        var return_products_form_select_box_choice = $(this).find('.return_choice').attr('id');

        if (return_products_form_select_box_quantity === "0") {
            $('#' + return_products_form_select_box_choice).prop("disabled", true);
        } else {
            $('#' + return_products_form_select_box_choice).prop("disabled", false);
        }
    });

    var scrollTop = $(window).scrollTop(),
        speed = Math.min(scrollTop * 0.8, window.innerHeight);

    $("html, body").animate({scrollTop: 0}, (speed * 2), function () {
    });
}

function createEtiquetteRetour(idOrder, parentPath, multiTransp, idOrderTransp) {

    var numRetour = "";
    if (typeof idOrderTransp != "undefined") {
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp +
            "&idOrderTransp=" +
            idOrderTransp
        );
    } else {
        numRetour = ajax_file(
            parentPath +
            "ajax_num_etiquette.php?idOrder=" +
            idOrder +
            "&multiTransp=" +
            multiTransp
        );
    }


    if (numRetour != "false") {

        var transporteur = '';
        // Dans le cas du multi transporteur on définie les bloc qui doive s'afficher
        if (multiTransp == 1) {
            transporteur = '_' + global_transporteur_id;
        }
        var commentaireRetourObjet = "";

        if (document.getElementById("commentaireRetourDiv" + transporteur))
            commentaireRetourObjet = document.getElementById(
                "commentaireRetourDiv"
            ).value;

        if (multiTransp !== 1) {
            if (document.getElementById("commentaireRetour") !== null) {
                commentaireRetourObjet = document.getElementById(
                    "commentaireRetour"
                ).value;
            }
        }

        var recommand = $(".recommandation").length;

        var has_errors = false;
        $(".return_quantity").each(function () {
            var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
            return_choice.removeClass('error');

            if ($(this).val() > 0 && return_choice.val() == '0') {
                has_errors = true;
                return_choice.addClass('error');
            }
        });

        if (!has_errors) {
            if (recommand) {
                $(".recommandation p span").css("color", "#000");
                $('p[id^="liste_motif_retour"] .select').css("border", "none");
                $("#divBtnPrintPdf").show();
            }
            showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, false, idOrderTransp);
        } else {
            if (recommand) {
                $(".recommandation p span").css("color", "#a40000");
                $('p[id^="liste_motif_retour"] .select').css(
                    "border",
                    "2px solid #a40000"
                );
            }
        }
    } else {
        alert('error')
    }
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf, idOrderTransp) {

    var idCmdOrder = multiTransp ? idOrderTransp : idOrder;

    data = "send_mail=true&choix=no_impression&idOrder=" + idCmdOrder + "&multiTransp=" + multiTransp + "&return_number=" + numRetour;
    if (!show_pdf) {
        data += "&" + $('#return_products_form_' + idOrder).serialize();
    }
    $.ajax({
        url: path_relative_root + 'ajax_create_etiquette.php',
        type: 'post',
        data: data,
        success: function (res) {
            closeMultiShad('popup_numero_retour_' + idOrder);
            if (res.substr(0, 5) == 'false') {
                alert(Translator.translate('js_error_return_label'));
            } else {
                if (show_pdf) {
                    window.location.href = res;
                } else {
                    location.reload();
                }
            }
        }
    });
}

function mySubmit(pType) {

    $('#btn_cmd_valid').next('.loader').addClass('loading');
    var form = document.getElementById("formPanier");

    setTimeout(function () {
        form.submit();
    }, 500);

    setTimeout(function () {
        $('#btn_cmd_valid').next('.loader').removeClass('loading');
    }, 2000);
}

function switch_gdt_txt_swiper() {
    if ($('.switch_gdt_btn').length) {
        window.setTimeout(function () {
            switch_gdt_btn_swiper = new Swiper('.switch_gdt_btn', {
                slidesPerView: 'auto',
                roundLengths: true,
                centeredSlides: true,
                slideToClickedSlide: true,
            });
        }, 500);
    }
}

function getWishlistProducts() {
    if (!$("body").hasClass("cart step_1")) {
        return true;
    }
    let array_elements = document.querySelectorAll('a[data-productid]');
    let array_ids = [];
    if (typeof array_elements != 'undefined' && array_elements.length > 0) {
        for (el of array_elements) {
            var id = el.getAttribute('data-productid');
            if (id != '' && id != 'undefined') {
                array_ids.push(id);
            }
        }
        let data = JSON.parse((JSON.stringify(Object.assign({}, array_ids))));

        $.ajax({
            type: 'get',
            url: path_relative_root + 'get_user_all_wishlist.php',
            data: data,
            success: function (response) {
                if (response && typeof response != 'undefined' && response.length > 0) {
                    let array_product_id = JSON.parse(response);
                    var i = 0;
                    userWishlist = [];
                    for (i = 0; i < array_product_id.length; i++) {
                        var wp = array_product_id[i];
                        userWishlist.push(wp.fk_produit_id + "_" + wp.fk_taille_id + "_" + wp.fk_couleur_id);
                    }

                    for (product_wishlist in array_product_id) {
                        $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').addClass("in_wishlist"); // in_wishlist utilisé sur hesperide et potentiellement d'autres sites
                        $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').addClass("existToWishlistButton");
                        $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').attr('data-wishlistproductid', array_product_id[product_wishlist].wishlist_product_id);
                    }
                }
            }
        });
    }
}

function loadGiftCardSections(sectionToShow) {
    var error = false;
    var minimalAmount = parseInt($('#selectBalance').attr('min'));

    if (sectionToShow == 'giftCardSendByEmail') {
        var amountSelector = $('#orderGiftCardForm input[type=number][name=balance]');
        amountSelector.removeClass('inputErr');
        if (!amountSelector.val() || isNaN(amountSelector.val()) || amountSelector.val() < minimalAmount || parseInt(amountSelector.val()) != amountSelector.val()) {
            amountSelector.addClass('inputErr');
            error = true;
        }


        if (!error) {
            $("#validForm").show();
            $('#giftCardSendByEmail #r_fname').removeAttr('disabled');
            $('#giftCardSendByEmail #s_sign').removeAttr('disabled');
            $('#giftCardSendByEmail #s_msgPerso').removeAttr('disabled');

            $('#validForm').click(function () {
                var error = 0;

                for (i = 0; i < $('#giftCardSendByEmail .w-input').length; i++) {
                    if ($('#giftCardSendByEmail .w-input').eq(i).val() == "") {
                        error += 1;
                        $('#giftCardSendByEmail .w-input').eq(i).addClass('inputErr');
                    }
                }

                if (error === 0) {
                    loadGiftCardSections('giftCardAddToCart');
                    $('#giftCardSendByEmail .w-input').removeClass('inputErr');
                    $('.info_error').hide();
                    /* Saved informations */
                    $('.gc_balance').html($("#selectBalance").val() + "€");
                    $('.r_email').html($('#r_email').val());
                    $('.r_fname').html($('#r_fname').val().replace(/</g, "&lt;").replace(/>/g, "&gt;"));
                    $('.s_sign').html($('#s_sign').val().replace(/</g, "&lt;").replace(/>/g, "&gt;"));
                    $('.s_msgPerso').html($('#s_msgPerso').val().replace(/</g, "&lt;").replace(/>/g, "&gt;"));
                    // $('.sectionGiftCard').hide();
                    // $('#giftCardAddToCart').show();
                } else {
                    $('.info_error').show();
                    return false;
                }
            });
        }
    } else if (sectionToShow == 'giftCardBlance') {
        $('#' + sectionToShow).slideToggle();
    }
}

function modBoxClose(evt) {

    if ($(this).attr('id') === 'choseCouponFreeProduct') {
        var loader = $('#formPanier .loader');

        if (loader.hasClass('loading')) {
            loader.removeClass('loading');
        }
    }

    if ($(this).attr('id') === 'alertboxlogin2') {
        $('#lightboxAchatExpressV1').slideDown();
    }

    $("html,body").css('position', 'initial');
    $("body").removeClass('noscroll');

    var shade = $('#shade');
    var modal = ($('.modbox.open').length) ? $('.modbox.open') : $('.modal_box.open');
    var rollover_achat_express = $('.rollover.achat_express');

    if (typeof evt !== "undefined") evt.preventDefault();

    if (!rollover_achat_express.is(':visible')) {
        shade.off('touchend', modBoxClose).removeClass('visible isExpress actif');
    }

    modal.removeClass('open');

    if ($("#shade_alert").hasClass("visible")) {
        $("#shade_alert").removeClass('actif visible');
        $("#shade").removeClass('isExpress visible');
    }
}

// pour chaque site
function extraOpenGlLightbox() {
    if ($("body").hasClass("product_page")) {
        $('.modal_shade').addClass('actif');
    }

    if ($("body").hasClass("gift_list_show")) {
        $('.modal_shade').addClass('actif');
    }
}

function extraCloseGlLightbox() {
    $('.modal_shade').removeClass('actif');
}

function changeFiltre(form, reset) {
    var value = getInputVal.bind(form);
    var type = value("type_tri"),
        idObj;
    var btn = this,
        idObjTmp;

    // If form must be reset
    if (reset) {
        if (filterformColors && filterformSize) {
            document.getElementById("wrapper_couleur_filtre").innerHTML =
                filterformColors;
            document.getElementById("wrapper_taille_filtre").innerHTML =
                filterformSize;
        }

        resetExtraFilters();
        form.reset();
        $("#filters_menu input[checked]")
            .not("#wrapper_sscat_filtre input[checked]")
            .removeAttr("checked"); //force suppression de tous les filtres checked (sauf ss-cat), ce qui arrive lorsqu'on revient d'une FP.
    }
    $("legend.open", form).each(function () {
        toggleFiltersMenu.call(this);
    });
    $("#type_tri").val(type);

    idObj = getCurrentIdRayon();

    // Display loader
    if (
        btn.nextElementSibling &&
        btn.nextElementSibling.classList.contains("button")
    ) {
        btn.nextElementSibling.style.display = "block";
    }

    // Get new elements
    if (from_type === "product") {
        generateNewBlocProd.call(form, 0, type, idObj, value("nb_prod"), btn);
    } else {
        generateNewBlocSearch.call(form, value("nb_prod"), 0, btn);
    }

    return false;
}

function changeajaxfilterRayon(form, typeFiltre, id, resetObj) {
    var value = getInputVal.bind(form), idTmp = id, id = value('type_nav', 'tous'),
        type = value('type_tri'), idObj,
        btn = this, idObjTmp;

    resetObj = resetObj || false;

    if($('#select_sscat_'+idTmp).hasClass('redirect_sscat') && $('#select_sscat_'+idTmp).is(':checked')) {
        var url_redirect = path_relative_root + $('#select_sscat_'+idTmp).attr("data-href");
        setTimeout(function(){ location.href = url_redirect; }, 1000);
    }

    idObj = getCurrentIdRayon();

    // Display loader
    if (btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {
        btn.nextElementSibling.style.display = 'block';
    }

// on decoche tout ppour faire une action radio
    if (resetObj == true) {
        resetCheckBox('eclats');
        resetCheckBox('brand');
        resetCheckBox('taille');
        resetCheckBox('couleur');
        resetCheckBox('promo');
        resetCheckBox('store');
        resetCheckBox('charact');
        resetCheckBox('theme');
        resetCheckBox('in_stock');
    }

    generateNewBlocFilter.call(form, type, idObj, 0, value('nb_prod'), btn, typeFiltre, id);

    // Prevent default action
    return false;
}

function paginate(page) {
    var form = document.getElementById("filters_form"),
        total_element = parseFloat($("#totalElems").val()),
        nb_prod = parseFloat($("#nb_prod").val()),
        nb_total_page = Math.ceil(total_element / nb_prod),
        pagination = $(".wrap_flitre_pager"),
        see_all = parseFloat($("#see_all").val()),
        thisPage = parseInt(page / nb_prod),
        initialPage = thisPage;

    page = page || 1;

    $("#page").change(function () {
        page = parseFloat($("#page").val());
        thisPage = parseInt(page / nb_prod + 1);
    });

    $("#totalElems").change(function () {
        total_element = $("#totalElems").val();
        nb_prod = Math.ceil(total_element / nb_prod);
    });

    $.ajax({
        url: path_relative_root + "ajax_reload_pagination.php",
        type: "get",
        data: {
            page: page,
            link: "paginationGoToPage()",
            nb_total_page: nb_total_page,
        },
        success: function (res) {
            var productLoaded = $(
                "#scroll_items .item_container:not(.push)"
            ).length;
            var percentLoaded = (productLoaded * 100) / total_element;

            pagination.each(function () {
                if (see_all === 0) {
                    this.innerHTML =
                        res +
                        '<div class="pagerNav end_pagi"><div class="nbProdDisplayed">' +
                        Translator.translate("pagerTxt1") +
                        " " +
                        productLoaded +
                        " " +
                        Translator.translate("pagerTxt2") +
                        " " +
                        total_element +
                        '</div><div class="loadedGauge"><span style="width: ' +
                        percentLoaded +
                        '%;"></span></div>' +
                        '<div class="loader_scroll">' +
                        '<button class="see_all_product button primary"><span>' +
                        Translator.translate("see_more_products") +
                        "</span></button>" +
                        '<div class="button primary loader"></div>' +
                        "</div></div>";
                } else {
                    this.innerHTML = "";
                }
            });

            $(".loader_scroll .see_all_product").on("click", function () {
                $(this).addClass("loading");

                if (
                    $(".dynasearchwrapper").length === 0 &&
                    $('#scroll_items .item:not(".push")').length < total_element
                ) {
                    if (initialPage >= 2 && thisPage + 1 === initialPage) {
                        paginationGoToPage(thisPage + 2, true, false);
                    } else {
                        paginationGoToPage(thisPage + 1, true, false);
                    }
                }
            });

            if (productLoaded == total_element) {
                $(".see_all_product").hide();
            }
        },
    });
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {

    page = page || 1;

    let idObj = getCurrentIdRayon();
    let page_bloc = parseInt(page - 1);

    /**
     * Mise à jour des valeurs de pages d'origine pour le scroll infini
     */
    if ($("#scroll_origin").length) {
        if (!fromScrollBottom && fromScrollTop) {
            $("#scroll_origin").val(page);
        } else if (fromScrollBottom && !fromScrollTop) {
            $("#initial_page").val(page);
        } else {
            $("#scroll_origin, #initial_page").val(page);
        }
    }

    if (fromScrollBottom || fromScrollTop) {
        $(".autoLoad").fadeTo("fast", 1);
    }

    if (from_type === "product") {
        generateNewBlocProd(
            page_bloc,
            null,
            idObj,
            null,
            null,
            fromScrollBottom,
            fromScrollTop,
            true
        );
    } else {
        generateNewBlocSearch(
            document.getElementById("nb_prod").value,
            page_bloc,
            null,
            fromScrollBottom,
            fromScrollTop
        );
    }
}

function loadProduct(type_filter, id, type_page, itm_rr_id, from_type) {

    var is_wishlist = false;
    var is_achat_express_v2 = (this.form !== undefined && this.form.elements['achatExpressV2'] !== undefined && !is_wishlist);
    var id_prod = typeof (id) == 'undefined' ? '' : ("_" + id);

    if (typeof (type_page) == 'undefined') {
        type_page = 'product';
    }

    if (typeof from_type == 'undefined') {
        from_type = 'listing';
    }
    var triggerType = type_filter;

    var data = '';

    if (!is_achat_express_v2) {

        if (type_filter === 'taille') {
            var couleur_id = $("#prod_couleur").val();
            var taille_id = $(this).data('sizeid');
        } else {
            var couleur_id = $(this).data('colorid');
            var taille_id = $("#prod_taille").val();
        }

        data += '&type=' + type_filter;
        data += '&type_page=' + type_page;
        data += '&produit_id_new=' + $(this).attr('data-produitid');

        if (typeof (id) != 'undefined') {
            data += '&couleurProd=' + $('#couleurProd' + id_prod).val();
            data += '&tailleProd=' + $('#tailleProd' + id_prod).val();
            data += '&produit_principal=' + $('#produit_principal' + id_prod).val();
            data += '&produit_id=' + $('#produit_id' + id_prod).val();
        } else {
            data += '&couleurProd=' + couleur_id;
            data += '&tailleProd=' + taille_id;
            data += '&produit_principal=' + $('#produit_principal').val();
            data += '&produit_id=' + $('#produit_id').val();
        }

    } else {
        var div_produit_achat_express_v2 = $(this).parents(".itmReload");
        var swiper_achat_express_v2 = $(this).parents('.swiper-container');

        var old_id = this.form.elements['produit_id'].value;
        var isCategSuper = 0;
        if (typeof this.form.elements['isCategSuper'] !== "undefined") {
            isCategSuper = this.form.elements['isCategSuper'].value;
        }
        var idSizeInput = 'idTaille_' + old_id + itm_rr_id;
        var new_id = id;
        var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
        var couleur_id = $(this).val();
        var taille_id = $("#" + this.form.elements[idSizeInput].value).val();

        data += 'produit_id=' + old_id;
        data += '&produit_principal=' + old_id;
        data += '&couleurProd=' + couleur_id;
        data += '&tailleProd=' + taille_id;
        data += '&qteProd=1';
        data += '&hdn_product_id=' + old_id;
        data += '&produit_id_new=' + new_id;
        data += '&type=' + type_filter;
        data += '&type_page=' + type_page;
        data += '&is_achat_express=0';
        data += '&is_achat_express_v2=1';
        data += '&cpt_prod=' + (cpt_prod - 1);
        data += '&isCategSuper=' + isCategSuper;
        data += '&fromType=' + from_type;
    }

    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_get_product_regroup_ref.php',
        data: data,
        beforeSend: function () {
            $('#bloc_btn_loader').show();
            $('#btn_error').fadeOut(300);
        },
        success: function (response) {

            try {
                response = $.parseJSON(response);
            }
            catch (e) {
                console.error('parseJSON');
                return;
            }

            if (response.success == 'ok') {

                if (window.dataLayer !== undefined) {
                    window.dataLayer.push({
                        'event': 'view_item',
                    });
                }

                var datas = response.datas;
                var product = datas.product;
                try {
                    if (triggerType == 'taille' && window.dataLayer !== undefined) {
                        window.dataLayer.push({
                            'event': 'changeSize',
                            'produit': {
                                'size_value': product.size.nom,
                                'ean': product.size.ean
                            }
                        });
                    }
                } catch (e) {
                    console.error('dataLayer.push');
                }

                if (type_page == 'product') {

                    var $html_main = $(datas.html_main);

                    if (is_achat_express_v2) {
                        // Photo et information
                        div_produit_achat_express_v2.replaceWith($html_main);
                        if ($('#oneProduct') != undefined && $('#oneProduct').hasClass('actif')) {
                            $('.itm_achat_express_' + new_id).addClass('full');
                        }

                        if (swiper_achat_express_v2) {
                            if (typeof (four_images) != 'undefined') {
                                enableSwiperFourImage();
                            } else {
                                var id_swiper = swiper_achat_express_v2.attr('id');
                                refreshSliderAchatExpress(id_swiper);
                            }
                        }
                        setTimeout(function () {
                            initItemSlider(false);

                            if ($('.assoSlider').length) {

                                $('.assoSlider').each(function () {

                                    this.swiper.update();
                                });
                            }
                        }, 2000);
                    } else {

                        var $fiche = $('#contents_wrapper');
                        var replace_main = new Array(
                            '#wrapper_product_name',
                            '#zoom_container',
                            '.prod_page_bot',
                            '.accordion_container',
                            '#carousel_wrapper',
                            '.product_contents',
                            'w-netreviews-stars',
                            'w-netreviews-comments',
                            '.product_cms_bloc',
                            '.color_filter',
                        );

                        for (var i = 0; i < replace_main.length; i++) {
                            var id = replace_main[i];

                            if (id === '.accordion_container') {

                                // Recover each accordion_item id so we can replace each one individually
                                $('.accordion_item', $fiche).each(function () {
                                    var acc_item_id = $(this).attr('id');

                                    if ($("#" + acc_item_id, $html_main).length) {
                                        // Is item was previously hidden, remove cache class
                                        if ($(this).hasClass('cache')) {
                                            $(this).removeClass('cache')
                                        }

                                        // Update item content in fiche with ajax response content
                                        $(this).html($("#" + acc_item_id, $html_main).html());
                                    } else {
                                        // If fiche accordion item isn't present in ajax response, hide it by adding cache class
                                        $(this).addClass('cache');
                                    }
                                });

                            } else if (id == 'w-netreviews-stars') { // New Avis verifies
                                $("#w-netreviews-stars", $fiche).html($('w-netreviews-stars', $html_main).html());
                                // Reload vuejs avis verifies
                                if (typeof wShop !== 'undefined' && wShop.$refs.wNetreviewsStars) {
                                    // Avis verifies stars
                                    if (wShop.$refs.wNetreviewsStars != 'undefined') {
                                        wShop.$refs.wNetreviewsStars.reload(product_refext_prod);
                                    }
                                }
                            } else if (id == 'w-netreviews-comments') { // New Avis verifies
                                $("#w-netreviews-comments", $fiche).replaceWith($('w-netreviews-comments', $html_main).wrap('<p/>').parent().html());
                                // Reload vuejs avis verifies
                                if (typeof wShop !== 'undefined' && wShop.$refs.wNetreviewsComments) {
                                    // Avis verifies reviews
                                    if (wShop.$refs.wNetreviewsComments != 'undefined') {
                                        wShop.$refs.wNetreviewsComments.reload(product_refext_prod);
                                    }
                                }
                            } else {
                                // In case info is not present in response, remove it (ex: cms-page-module on one ref but not on an other)
                                if ($(id, $fiche).length && $(id, $html_main).length) {
                                    $(id, $fiche).html($(id, $html_main).html());
                                } else {
                                    if ($(id, $fiche).length && !$(id, $html_main).length) {
                                        $(id, $fiche).replaceWith('');
                                    }
                                }
                            }
                        }

                        refreshEventsLoadProduct();

                        // depending on type_filter, update either color checked or size selected
                        if (type_filter === 'taille') {
                            $('#prod_taille [value=' + taille_id + ']').prop('selected', true);
                        }

                        if (type_filter === 'couleur') {
                            $('#prod_couleur #color_' + couleur_id).prop('checked', true);
                        }

                        if (is_achat_express_v2) {
                            // TO DO
                        } else {
                            var firstColorId = product.color.id;
                            var firstSizeId = product.size.id;
                            if (response.stock[firstColorId][firstSizeId]) {
                                if (product.enableAlertStock) {
                                    $("#bloc_add_alert").hide();
                                }
                                $("#add_basket_wrapper").show();
                                $('#button_add').addClass('active');
                            } else {
                                if (product.enableAlertStock) {
                                    $("#bloc_add_alert").show();
                                }
                                $("#add_basket_wrapper").hide();
                                $('#button_add').removeClass('active');
                            }
                        }

                        $('.jqzoom').on('doubletap', function () {
                            zoomImg.call(this);
                            $('#zoomBox').addClass('active');

                            contentMovable.addClass('visible');
                            var zoomboxWidth = $('body').width(),
                                scrollTo = zoomboxWidth / 2;
                            setTimeout(function () {
                                $('#zoomBox').scrollLeft(scrollTo);
                            }, 'slow');
                        });

                    }
                }

                // RECHARGEMENT STYLE INPUT
                $('.selectStyled').each(function () {
                    $(this).selectStyled();
                });

            } else {
                $('#btn_error').fadeIn();
            }
        },
        complete: function () {

            $('#bloc_btn_loader').fadeOut(300);

            if (window.lazyload) {
                lazyload.init();
            }

            var form = document.getElementById('item_add_form');
            if (!is_achat_express_v2) {
                colors = form.itm_colour;
                updateColor.call(colors.options[colors.selectedIndex]);
            }

            if ($('.rollover.achat_express').is(':visible')) {
                hidePopinColorSize();
            }

            setTimeout(function () { $(".zoom_indicator").fadeOut(800) }, 2500);

            setTimeout(function () {
                $("#shade").removeClass('visible');
                $("body").removeClass('noscroll');

                if ($("#zoom_container .swiper-container .swiper-slide").length > 1) {
                    window.prodSwiperSlider = new Swiper('#zoom_container .swiper-container', {
                        slidesPerView: 1,
                        preloadImages: true,
                        lazyLoading: true,
                        loop: true,
                        nextButton: '.swiper-button-next',
                        prevButton: '.swiper-button-prev'
                    });
                }
            }, 1000);

            if ($(".product_fields.size_filter .achat_express .choices_list .form_itm").length === 1) {
                $("#button_add, #button_add_float").addClass("active");
            }

            // WPOS Exclusive - Get if product is available in store use in WPOS
            var idStore = getCookie('wshopStoreId');
            if (idStore != undefined && idStore > 0) {
                var color = $("#prod_couleur").val();
                var size = $("#prod_taille").val();
                getProductInfoByStore(new_id, color, size, idStore)
            }
        }
    });
}

// Refresh product after ajax event
function refreshEventsLoadProduct() {

    if ($(".product_page")) {
        initFpSliders();
    }

    if (document.getElementById("alma-widget") !== null) {
        initAlmaWidget();
    }
}

function initFpSliders() {

    if ($("#carousel_wrapper .swiper-container").length) {
        $("#carousel_wrapper").attr("id", "carousel_wrapper_new");
    }
}

// Triggers on attribute alteration
function onAttrSelect(idProduit) {
    var form = document.getElementById('item_add_form'),
        colors = form.itm_colour,
        sizes = form.itm_size,
        quantities = form.itm_quantity,
        color, size;

    if (this.value) {
        this.classList.remove('error');
    }

    color = (colors && colors.selectedIndex !== -1) ? colors.value : 'none';

    if (color !== 'none') {
        updateColor.call(colors.options[colors.selectedIndex]);
        if (this !== window && this.classList.contains('color')) {
            setAvailableSizes.call(this);
        }
    }

    size = (sizes !== undefined && sizes.selectedIndex !== -1) ? sizes.value : 'none';
    if (sizes !== undefined) {
        var sizeValue = (size && sizes[sizes.selectedIndex] !== undefined) ? sizes[sizes.selectedIndex].text : 0;

        var idProduitSize = (sizes && sizes.selectedIndex !== -1 && typeof sizes.options != 'undefined') ? sizes.options[sizes.selectedIndex].dataset.produit_id : idProduit;

        if (idProduitSize > 0) {
            idProduit = idProduitSize;
        }
    }

    if (quantities) {
        $.ajax({
            url: path_relative_root + 'ajax_liste_qte.php',
            type: 'get',
            async: false, // Should be async but would then need a loader so...
            dataType: 'json',
            data: { idprod: idProduit, idcouleur: color, idtaille: size, isGlobalEActive: isGlobalEActive, json: '1' },
            success: function(res) {

                var content = res.content;
                quantities.parentElement.innerHTML = content.trim();

                if (size != '') {
                    result = $($('#qteProd option:selected'), content);

                    /**
                     * Flag alert stock
                     * If enable, bloc_add_alert exist in DOM
                     * If disabled, bloc_add_alert don't exist in DOM
                     */
                    if (res.inventory.enableAddToCart) {
                        $("#bloc_add_alert").hide();
                        $("#add_basket_wrapper").show();
                    } else {
                        $("#bloc_add_alert").show();
                        $("#add_basket_wrapper").hide();
                    }

                } else if (size == "" && res.disable_addtocart == true) {
                    onAttrDisableAddCart(true, false);
                }

                checkProductInWishlist();

                if ($('.rollover.achat_express.size').is(':visible')) {
                    hidePopinColorSize();
                }

                // WPOS Exclusive - Get if product is available in store use in WPOS
                var idStore = getCookie('wshopStoreId');
                if (idStore != undefined && idStore > 0) {
                    var color = $("#prod_couleur").val();
                    var size = $("#prod_taille").val();
                    getProductInfoByStore(idProduit, color, size, idStore)
                }
            }
        });
    }
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id, prod_id) {
    var control, prod_id;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : "";

    if (this.checked) {
        control = form.elements.namedItem(form.getValue("idCouleur"));
        control.value = this.value.trim();

        if (is_regroup) {
            loadProduct.call(this, "couleur", prod_id, 'produit', itm_rr_id);
        } else {
            var img = $(this).data("pic");

            changeVisu("img_large", img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }
}

function sticky_filters() {
    $(window).scroll(function() {
        var menu_sticky = $(".filtersArea");
        var menu_sticky_top = menu_sticky.scrollTop();
        var banner_nav = $(".banner_wrapper");
        var banner_nav_height = banner_nav.height();
        var scroll_top = $(this).scrollTop();

        if (scroll_top > (banner_nav_height - menu_sticky_top)) {
            menu_sticky.addClass('sticky');
        } else {
            menu_sticky.removeClass('sticky');
        }
    });
}

function checkProductInWishlist() {
    if (typeof button_add_to_wishlist_initial_click_event === "undefined") {
        button_add_to_wishlist_initial_click_event = $("#addToWishlistButton").attr("onclick");
    }
    if (typeof button_add_to_wishlist_initial_label === "undefined") {
        button_add_to_wishlist_initial_label = $("#addToWishlistButton").html();
    }

    let productId = $("#produit_id").val();
    let colorSelected = $("#prod_couleur").val();
    let sizeSelected = $("#prod_taille").val();

    if (productId !== undefined && colorSelected !== "" && sizeSelected !== "") {
        $.ajax({
            type: "get",
            url: path_relative_root + "get_user_wishlist.php",
            data: {
                product_id: productId,
                size_id: sizeSelected,
                color_id: colorSelected,
            },
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    changeBtnAddWishlist(true);
                } else {
                    changeBtnAddWishlist(false);
                }
            },
            error: function () {
                // Handle AJAX error if needed
                changeBtnAddWishlist(false);
            }
        });
    } else {
        changeBtnAddWishlist(false);
    }
}

function changeBtnAddWishlist(in_wishlist) {
    // Restore initial onclick attribute
    $("#addToWishlistButton").attr("onclick", button_add_to_wishlist_initial_click_event);

    // Update text, classes, and icon based on wishlist status
    let button = $("#addToWishlistButton");
    let buttonText = in_wishlist ? Translator.translate("exist_to_wishlist_button_label") : Translator.translate("wishlist");
    let iconClass = in_wishlist ? "heart_b" : "heart";

    button.find("span").text(buttonText);
    button.removeClass("heart heart_b").addClass(iconClass);
    if (!in_wishlist) {
        button.removeClass("existToWishlistButton");
    } else {
        if(!button.hasClass('existToWishlistButton')) {
            button.addClass("existToWishlistButton");
        }
    }
}

function wording_faq_cart() {
    if ($("body.cart .questions_frequentes").length && $('.questions_frequentes_title').length === 0) {
        $('.questions_frequentes').prepend('<p class="questions_frequentes_title">' + Translator.translate('questions_frequentes_title') + '</p>');
    }

    if ($("body.cart #cart_total").length && $('#cart_total .bill_line.total_produit label.new_txt').length === 0) {
        $('body.cart #cart_total .bill_line.total_produit label').html(Translator.translate('total_product')).addClass('new_txt');
        $('body.cart #cart_total .bill_line.hide_shippingfees label').html(Translator.translate('livraison_a_partir_de')).addClass('new_txt');
    }
}

function debounce(func, wait) {
    let timeout;
    return function(...args) {
        const later = () => {
            clearTimeout(timeout);
            func.apply(this, args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

/////// Update cart from item form \\\\\\\

// Checks form before updating cart
function checkFormUpdateCart(addToCart) {

    if (typeof this.form.options !== "undefined" && $("input[name='options']:checked").length > 0) {
        return addConfiguredProduct();
    }
    var output = function() { return false };
    var value = getInputVal.bind(this.form);
    var colour = value('itm_colour', 'none');
    var size = value('itm_size', 'none');

    var quantity = value('itm_quantity', 'none');
    var head_offset = (window.innerWidth * 0.2921875);
    var form_offset;
    var scroll_delay;
    var produit_id = $('#produit_id').val();

    var is_achat_express_v2 = (this.form !== undefined && this.form.elements['achatExpressV2'] !== undefined);
    var productBatchId = $(this).data('productid');

    if (productBatchId != produit_id && productBatchId != undefined) {
        this.form.productBatchId = $(this).data('productid');
        this.form.titreObjet = $(this).data('producttitle');
        this.form.prix = $(this).data('productprice');
        this.form.qte = $(this).data('productbatchnumber') * quantity;
    } else {
        this.form.productBatchId = '';
    }

    if ($('.prod_page_bot', '#content_product').length > 0) {
        form_offset = $('.prod_page_bot', '#content_product').get(0).offsetTop;
    }

    if (window.dataLayer !== undefined && (typeof dataLayer_v2 == 'undefined' || dataLayer_v2 != 1)) {
        window.dataLayer.push({
            'event': 'addToCart',
            'ecommerce': {
                'currencyCode': 'EUR',
                'add': {
                    'product_ref': value('itm_ref')
                }
            }
        });
    }

    if (is_achat_express_v2) {
        $("#wishlistAddToCart" + suffix).hide();
        quantity = 0;
        var suffix = "_" + this.form.elements['suff'].value;
        var colorId = this.form.elements['couleurProd'].value;
        var sizeId = addToCart ? this.form.elements['itm_size'].value : $(this).val();
        var productId = this.form.elements['produit_id'].value;

        datas = { idProduit: productId, idCouleur: colorId, idTaille: sizeId, suffix: suffix };
        quantity = checkQty(datas);

        if (colorId && sizeId && quantity) {
            if (addToCart) {
                output = sendFormUpdateCart.bind(this.form);
            } else {
                $.ajax({
                    type: "get",
                    url: path_relative_root + "get_user_wishlist.php",
                    data: {
                        product_id: productId,
                        size_id: sizeId,
                        color_id: colorId,
                    },
                    success: function (response) {
                        var userWishlist = JSON.parse(response);
                        if (userWishlist.length) {
                            $("#addToWishlistButton" + suffix).addClass('existToWishlistButton');
                        } else {
                            $("#addToWishlistButton" + suffix).removeClass('existToWishlistButton');
                        }
                    },
                    error: function () {
                        $("#addToWishlistButton" + suffix).removeClass('existToWishlistButton');
                    }
                }).done(function() {
                    $("#wishlistAddToCart" + suffix).css('display', 'flex');
                    $("#prod_info" + suffix + " .block_sizeguide").hide();
                    $("#prod_info" + suffix + " .titre_choices_list").hide();
                });
            }
        }
    } else {
        if (colour && typeof(size) != 'object' && size && quantity) {
            output = sendFormUpdateCart.bind(this.form);
        } else {
            if (form_offset) {
                scroll_delay = $(window).scrollTop() * 0.8;
                scroll_delay = Math.min(scroll_delay, 400);

                $('html, body').animate({ scrollTop: form_offset - head_offset }, scroll_delay);
            }

            if (!$('.input_check.size:checked').length && $('.noSizeSelected').length) { // Ex : Jonak

                $('.noSizeSelected').addClass('error');
            }

            if (!colour) {
                this.form.itm_colour.classList.add('error');
            }

            if (!size) {
                this.form.itm_size.classList.add('error');
            }

            if (!quantity) {
                this.form.itm_quantity.classList.add('error');
            }

            window.setTimeout(function() {
                var loader = $('.loader');

                if (loader.hasClass('loading')) {
                    loader.removeClass('loading');
                } else {
                    loader.parent().removeClass('loading');
                }
            }, 400);
        }
    }

    return output();
}

function addToWishlist() {

    var btn = $(this);
    var idElt = btn.data('idelt');
    var form = btn.parents('form#prod_info_' + idElt);

    if (btn.parent().parent().attr('id') == "lightboxAchatExpressV1Brand") {
        // achat express V1
        var productId = this.getAttribute("data-produitid");
        var sizeId = $("#lightboxAchatExpressV1 input[name='itm_size_" + productId + "']:checked").val();
        var colorId = $("#lightboxAchatExpressV1 input[name='itm_color_" + productId + "']:checked").val();
        var currentPage = 0;
    } else if (typeof form !== "undefined" && form.length) {
        // achat express V2
        var productId = form.find('[name="produit_id"]').val();
        var colorId = form.find('[name="couleurProd"]').val();
        var sizeId = form.find('[name="itm_size"]:checked').val();
        var currentPage = 0;
    } else {
        // product page
        var form = document.getElementById("item_add_form");
        var colorId = form.elements['itm_colour'].value;
        var sizeId = form.elements['itm_size'].value;
        var productId = form.elements['itm_id'].value;
        btn = $("#addToWishlistButton");
        if (!sizeId) {
            $("#prod_taille").addClass("error");
            return;
        }
    }

    let datas = {
        product_id: productId,
        color_id: colorId,
        size_id: sizeId,
        need_color_id: 'true',
        need_size_id: 'true'
    };

    if (!btn.hasClass('existToWishlistButton')) {
        $.ajax({
            url: path_relative_root + "ajax_add_to_wishlist.php",
            type: 'post',
            data: datas
        })
        .done(function (resp) {
            if (resp.error) {
                var message = resp.result.error_message ? resp.result.error_message : resp.message;
                if (resp.message === "login_required") {
                    let modalTextWishlist = '<div class="form_submit form_submit_wishlist">';
                    modalTextWishlist += '<button type="button" onclick="hideAchatExpress.call(this)" class="close_modal"><span class="txt">' + Translator.translate('close') + '</span></button>';
                    modalTextWishlist += '<div class="box_txt_wishlist">' + resp.result.error_message + '</div>';
                    modalTextWishlist += '<button class="button primary"  href="javascript:void(0)" onclick="wishlistRedirectLogin(\'' + productId + '\', \'' + currentPage + '\');">';
                    modalTextWishlist += '<span>' + resp.result.btn_connexion + '</span>';
                    modalTextWishlist += '</button>';
                    modalTextWishlist += '</div>';
                    alert('<div class="rollover achat_express">' + modalTextWishlist + '</div>');
                    form.find('.rollover.achat_express').hide();
                } else if (resp.message != "error") {
                    $(".disabledBtnTooltip").addClass('blink');
                    setTimeout(function() {
                        $(".disabledBtnTooltip").removeClass('blink');
                    }, 2000);

                    // if the button is inside the div #lightboxAchatExpressV1, display the error message in the lightbox below .size_select_wrapper
                    if (btn.parent().parent().attr('id') == "lightboxAchatExpressV1Brand") {
                        var errorDiv = btn.parent().parent().parent().find('.size_select_wrapper');
                        errorDiv.append('<div class="error">' + Translator.translate('choose_size') + '</div>');
                        setTimeout(function() {
                            errorDiv.find('.error').remove();
                        }, 3500);
                    }
                } else {
                    alert(Translator.translate(message));
                }
            } else {
                btn.addClass("existToWishlistButton");
                btn.data('wishlistproductid', resp.result.wishlist_product_id);
                // Update wishlist count in menu
                addToWishlistUpdateHeaderCount(resp.result.wishlist_size);

                /*Recharge la page pour le slider wishlist*/
                if ($('body.cart.step_1').length) {
                    window.location.reload();
                }

                let dl_v3_obj = resp.result.dlv3_wishlist_item;
                if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null && Object.keys(dl_v3_obj).length > 0) {
                    DataLayerTrigger.addToWishList(dl_v3_obj['items'], dl_v3_obj['actionField'])
                }
            }
        });
    } else {
        datas.remove = true;

        $.ajax({
            url: path_relative_root + "ajax_remove_from_wishlist.php",
            type: 'post',
            data: datas
        })
        .done(function (resp) {
            if (!resp.error) {
                btn.removeClass("existToWishlistButton");
                btn.data('wishlistproductid', '');
                addToWishlistUpdateHeaderCount(resp.wishlistLength);

                /*Recharge la page pour le slider wishlist*/
                if ($('body.cart.step_1').length) {
                    window.location.reload();
                }
            }
        });
    }
}

function addToWishlistUpdateHeaderCount(wishlistLength) {
    if (wishlistLength > 0) {
        $(".menu_slider .highlight").html("(" + wishlistLength + ")");
    } else {
        $(".menu_slider .highlight").html("");
    }
}

function checkQty(datas) {
    var resultat = false;

    $.ajax({
        url: path_relative_root + 'ajax_liste_qte.php',
        type: 'get',
        async: false,
        dataType: 'json',
        data: { idprod: datas.idProduit, idcouleur: datas.idCouleur, idtaille: datas.idTaille, json: '1' },
        success: function(res) {
            var blocAlert = res.enableAlertStock ? $("#bloc_add_alert" + datas.suffix) : $("#bloc_size_not_available" + datas.suffix);
            var blocSize = $("#ligne_pointure" + datas.suffix);
            var block_sizeguide = $("#ligne_pointure" + datas.suffix).parents('.rollover.achat_express').find('.block_sizeguide');
            var blocWishlist = $("#wishlistAddToCart" + datas.suffix);

            if (blocAlert != undefined && blocAlert.length > 0) { // Alert stock active
                if (res.inventory.enableAddToCart) {
                    blocAlert.hide();
                    blocSize.show();
                    resultat = true;
                    blocAlert.find('.alert_stock').hide();
                } else {
                    // Reset alert bloc if previously used
                    if (blocAlert.find('.bloc_add_alert_form').css('display') == 'none') {
                        blocAlert.find('.bloc_add_alert_confirmation').hide();
                        blocAlert.find('.bloc_add_alert_erreur').hide();
                        blocAlert.find('.bloc_add_alert_form').show();
                    }

                    blocAlert.show();
                    blocSize.hide();
                    resultat = false;
                    blocAlert.find('.alert_stock').show();
                    block_sizeguide.hide();
                    blocWishlist.hide();
                }
            } else {
                resultat = true;
            }
        }
    });

    return resultat;
}

function updateColor() {}

function shadAchatExpressNotVisible() {
    if (!$('body').hasClass('homepage')) {
        $('body').addClass('noscroll');
        $("#shade").addClass("isExpress visible");
    }
}

function dataLayer_associated_product_mobile () {
    if ($(this).parents(".assos_product").length) {
        // Prevents the event from bubbling up to the parent
        // & triggering multiple datalayers events
        $('form[id^="prod_info_"]').on('click', function(event) {
            event.stopPropagation();
        });
        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        if (product_id !== '') {
            if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
                DataLayerTrigger.selectItem(dl_v3_impressions_look, dlv3_action_fields_look, product_id);
            }
        };
    }

    if ($(this).parents(".assos_rayon").length) {
        var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
        if (product_id !== '') {
            if (typeof dataLayer_v3 !== 'undefined' && dataLayer_v3 !== '' && dataLayer_v3 !== null) {
                DataLayerTrigger.selectItem(dl_v3_impressions_like, dlv3_action_fields_like, product_id);
            }
        }
    }
}

function openAchatExpressV2(type) {
    var form = this.form;
    var divColor = $(form).find('.productColorFieldset');
    var divSize = $(form).find('.productSizeFieldset');
    var colors = divColor.find('.form_itm.check.color');
    var sizes = divSize.find('.form_itm.check.size');
    var blocAlert = $(form).find('.btnAddBasketWrapper');
    var blocWishlist = $(form).find('.wishlistAddToCart');
    var nbColors = colors.length;
    var nbSizes = sizes.length;
    var block_sizeguide = $(form).find('.block_sizeguide');
    if (nbColors == 1 && nbSizes == 1) {
        // 1 color + 1 size => display addTo cart + wishlist
        shadAchatExpressNotVisible();
        var colorId = form.elements['couleurProd'].value;
        var sizeId = form.elements['itm_size'].value;
        var productId = form.elements['produit_id'].value;
        $.ajax({
            type: "get",
            url: path_relative_root + "get_user_wishlist.php",
            data: {
                product_id: productId,
                size_id: sizeId,
                color_id: colorId,
            },
            success: function (response) {
                var userWishlist = JSON.parse(response);
                if (userWishlist.length) {
                    blocWishlist.find(".wishlistBtn").addClass('existToWishlistButton');
                } else {
                    blocWishlist.find(".wishlistBtn").removeClass('existToWishlistButton');
                }
            },
            error: function () {
                blocWishlist.find(".wishlistBtn").removeClass('existToWishlistButton');
            }
        }).done(function() {
            $(form).find('.rollover.achat_express').show();
            divColor.hide();
            divSize.show();
            blocWishlist.css('display', 'flex');
        });
    } else {
        blocAlert.hide();
        if (type == 'color') {
            if (nbColors == 1) {
                // 1 color => show sizes
                shadAchatExpressNotVisible();
                $(form).find('.rollover.achat_express').show();
                divColor.hide();
                divSize.show();
                blocWishlist.hide();
            } else {
                // show colors
                shadAchatExpressNotVisible();
                $(form).find('.rollover.achat_express').show();
                divColor.show();
                divSize.hide();
                blocWishlist.hide();
            }
        } else if (type == 'size') {
            if (nbSizes == 1) {
                console.log('size');
                // 1 size => display addTo cart + wishlist
                shadAchatExpressNotVisible();
                var colorId = form.elements['couleurProd'].value;
                var sizeId = form.elements['itm_size'].value;
                var productId = form.elements['produit_id'].value;
                $.ajax({
                    type: "get",
                    url: path_relative_root + "get_user_wishlist.php",
                    data: {
                        product_id: productId,
                        size_id: sizeId,
                        color_id: colorId,
                    },
                    success: function (response) {
                        var userWishlist = JSON.parse(response);
                        if (userWishlist.length) {
                            blocWishlist.find(".wishlistBtn").addClass('existToWishlistButton');
                        } else {
                            blocWishlist.find(".wishlistBtn").removeClass('existToWishlistButton');
                        }
                    },
                    error: function () {
                        blocWishlist.find(".wishlistBtn").removeClass('existToWishlistButton');
                    }
                }).done(function() {
                    $(form).find('.rollover.achat_express').show();
                    divColor.hide();
                    divSize.show();
                    blocWishlist.css('display', 'flex');
                });
            } else {
                // show sizes
                shadAchatExpressNotVisible();
                $(form).find('.rollover.achat_express').show();
                divColor.hide();
                divSize.show();
                block_sizeguide.show();
                blocWishlist.hide();
            }
        }
    }
    return;
}

function dataLayer_associated_product () {
}

function hideAchatExpress() {

    $("#shade").removeClass("visible").removeClass("isExpress");
    $(this.form).find('.achat_express').hide();

    $(this.form).find('.actif').removeClass('actif');

    $(this.form).find('.actif').removeClass('actif');
    var $div_alert_stock = $(this.form).find('.bloc_add_alert');
    $div_alert_stock.hide();

    var lightbox = $(this.form).find('.achat_express');
    var lightBoxAlertStock = lightbox.hasClass('displayInfoStock');

    if (lightBoxAlertStock) {
        lightbox.removeClass('displayColors');
    }

    if ($('#alert_box').hasClass('open')) {
        $('#alert_box').removeClass('open');
        $('#shade_alert').removeClass('actif visible');
    }
}


// FUNCTION PUSH INTO DATALAYER
function pushIntoDatalayer(data) {
    if (typeof (data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof (data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}


/**
 * GET dataLayer object data for PINTERST EVENTS
 * @param {string} type
 * @param {string} id
 * @param {string} currency
 */
function getDataLayerObjectInfo(type, id, currency) {
    var impressions = data_layer_products;
    let data = {
        type: type,
        id: id,
        json: 'json'
    };


    var dataInfo = {
        event: "productImpressions",
        ecommerce: {
            currencyCode: currency,
            impressions: [impressions]
        }
    }

    $.ajax({
        url: path_relative_root + 'ajax_object_data.php',
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {
            // Set event ID for product detail dataLayer
            if (typeof (res) == 'object') {
                dataInfo['ecommerce']['event_id'] = res.dataLayerData.event_id;
                pushIntoDatalayer(dataInfo);
            }
        }
    });
}
